import { Button, Typography, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import { useState, useEffect } from "react"

import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"

const ActivateAccount = () => {
  const [buttonText, setButtonText] = useState("Activate account")
  const [timeLeft, setTimeLeft] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)
  const [apiMessage, setAPIMessage] = useState("")
  const [alertSeverity, setAlertSeverity] = useState("")
  const navigate = useNavigate()

  const API_URL = "api/user/activate-account/"

  const { activationToken } = useParams()

  const makeAPIRequest = async () => {
    // console.log(activationToken)
    try {
      const response = await axios.patch(API_URL, {
        account_activation_token: activationToken,
      })
      // console.log(response)
      if (response.data.message === "link is valid") {
        setAlertSeverity("success")
        setAPIMessage("Your email has been verified!")

        setTimeout(() => {
          const token = window.localStorage.getItem("factored_token")
          if (token) {
            navigate("/dashboard")
          } else if (!token) {
            navigate("/login")
          }
        }, 1500)
      }

      // console.log(response)
    } catch (error) {
      setAlertSeverity("error")
      if (error.response.data.message === "link is invalid") {
        setAPIMessage(
          "Sorry, it looks like this link is invalid. Please log in and request a new link.",
        )
      }
      if (error.response.data.message === "link has expired") {
        setAPIMessage(
          "Sorry, it looks like this link has expired. Please log in and request a new link.",
        )
      }
    }
  }

  const handleButtonClick = () => {
    setDisplayAlert(true)
    makeAPIRequest()
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Click on the below button to activate your account.
      </Typography>

      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handleButtonClick}
        // disabled={buttonText === "Resend Email" ? false : true}
      >
        <Typography variant="h6">{buttonText}</Typography>
      </Button>
      {apiMessage && <Alert severity={alertSeverity}>{apiMessage}</Alert>}
    </GenericContainer>
  )
}
export default ActivateAccount
