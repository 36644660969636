// For better UX, the advance creation process is broken down into 4 steps
// These 4 steps are 4 different pages linked in a logical chain

// Stage 3 allows the user to specify how much capital they'd like to borrow
// and what duration (in months) the advance will be

// This stage also shows the interest rate (fee) applied
// and the total monthly payout

import { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import axios from "axios"
import {
  Box,
  Button,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"

import GenericContainer from "../../ui/GenericContainer"
import AdvanceStepper from "../../components/AdvanceStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import StageThreeSlider from "../../components/StageThreeSlider"
import AdvanceDurationSlider from "../../components/AdvanceDurationSlider"
import { formatFiatNumberTwoDecimals } from "../../utils/common"
import Tooltip from "@mui/material/Tooltip"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const NewAdvance3 = () => {
  const styles = {
    displayFlex: {
      display: "flex",
    },
    rightSideEntry: {
      marginLeft: "auto",
      paddingRight: "2rem",
      fontWeight: "bold",
    },
  }

  // We fetch the advance ID from the URL using the below hook
  let { advanceID } = useParams()

  const [errorText, setErrorText] = useState("")
  const [isLoading, setIsLoading] = useState(true)

  // Initial useEffect to check for the local auth token
  // and fetching of a single advance
  useEffect(() => {
    document.title = `Factored - Advance ${advanceID}`
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getSingleAdvance()
      getRates()
      getArrangementFee()
    }
  }, [])

  // This fetches the advance using the ID from the useParams hook
  const getSingleAdvance = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/advances/${advanceID}/`)
      // console.log(data)
      setNewAdvance(data)
      // console.log(data)
      setIsLoading(false)
      calculateMonthlyPayment()
    } catch (error) {
      console.log(error)
      console.log("getting advance error")
      setIsLoading(false)
    }
  }

  const [latestArrangementFee, setlatestArrangementFee] = useState(1.05)
  const [advanceMaxAmount, setAdvanceMaxAmount] = useState(10000)

  const getArrangementFee = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/rates/arrangement-fee/`)
      setlatestArrangementFee(data["latest_arrangement_fee"])
      setAdvanceMaxAmount(parseFloat(data["advance_max_amount"]))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log("error getting arrangement fee")
    }
  }

  const getRates = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/rates/`)
      setInterestRates(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      console.log("error getting rates")
    }
  }

  const [newAdvance, setNewAdvance] = useState({})

  const navigate = useNavigate()

  // The below is a list of interest rates that the frontend uses for calculations
  // These calculations are for display purposes only
  // And the backend does its own calculations so that it is not possible
  // for the client to game the calculations

  const [interestRates, setInterestRates] = useState([])

  const {
    monthly_rent,
    loan_amount,
    loan_term,
    estimated_loan_monthly_payment,
    loan_amount_with_arrangement_fee,
    reason,
  } = newAdvance

  function pmt(rate, nper, pv) {
    return (pv * rate * (1 + rate) ** nper) / ((1 + rate) ** nper - 1)
  }

  let arrangementFee = 0
  let monthlyFee = 0

  const calculateMonthlyPayment = () => {
    if (interestRates.length === 0) {
      return 1
    } else {
      const rateArrayItem = interestRates.find(
        (singleRate) => singleRate[0] === loan_term,
      )

      const rate = rateArrayItem[1]

      const principal = parseFloat(loan_amount)

      const new_loan_amount_with_arrangement_fee =
        principal * latestArrangementFee

      arrangementFee = new_loan_amount_with_arrangement_fee - principal

      // calculating monthly rate
      const newRate = rate / 12
      // loan duration e.g. 12, 24, etc, aka amount of payments
      const nper = loan_term

      // present value, either just the pricipal or loan amount with arrangement fee
      const pv = principal

      const correctMonthlyPayment = pmt(newRate, nper, pv)

      const monthlyOriginationFee = arrangementFee / loan_term

      setNewAdvance({
        ...newAdvance,
        loan_interest_rate: rate,
        estimated_loan_monthly_payment:
          correctMonthlyPayment + monthlyOriginationFee,
        loan_amount_with_arrangement_fee: new_loan_amount_with_arrangement_fee,
      })
    }
  }

  const calculateMonthlyFactoredFee = () => {
    if (interestRates.length === 0) {
      return 1
    } else {
      return (
        (((estimated_loan_monthly_payment * loan_term - loan_amount) /
          loan_amount) *
          100) /
        loan_term
      )
    }
  }

  useEffect(() => {
    // This check is applied because we don't want to trigger the cal
    // if the fields are empty
    // console.log({ actualArrangementFee })
    if (monthly_rent && loan_amount >= 3000) {
      calculateMonthlyPayment()
      calculateMonthlyFactoredFee()
    }
  }, [loan_term, loan_amount])

  // This sends a PATCH request to the backend with new advance details
  // And then navigates the user to the next stage
  const handleSubmit = (e) => {
    e.preventDefault()
    const updateExistingAdvance = async () => {
      try {
        const response = await axios.patch(`api/advances/${advanceID}/`, {
          loan_amount: parseFloat(loan_amount),
          loan_term: parseInt(loan_term),
          reason
        })

        setNewAdvance(response.data)
        navigate(`/new-advance-4/${advanceID}/`)
      } catch (error) {
        setErrorText(error.response.data.detail)
      }
    }
    updateExistingAdvance()
  }

  // This updates newAdvance and the form whenever there is a change detected
  const handleChange = (e) => {
    setNewAdvance({
      ...newAdvance,
      [e.target.name]: e.target.value,
    })
  }

  // Values for the term (duration) of the loan
  const termDurationOptions = ["Please select", 12, 24, 36, 48, 60]

  // If the page is loading, we display this placeholder,
  // and wait until the advance details render
  if (isLoading) {
    return (
      <div className="generic-container">
        <h2>Please wait...</h2>
      </div>
    )
  }

  const generateToolTip = () => {
    const feeForToolTip = loan_amount_with_arrangement_fee - loan_amount
    return `This total includes the arrangement fee of £${feeForToolTip}`
  }

  return (
    <>
      (
      <GenericContainer>
        {Object.keys(newAdvance).length >= 1 && (
          <>
            <AdvanceStepper stepNumber={2}></AdvanceStepper>
            <Typography variant="h2" sx={{ textAlign: "center" }}>
              Advance Amounts
            </Typography>
            {/* <label>Amount of rent you're selling</label> */}
            {/* <TextField
          id="outlined-basic"
          label="How much rent do you want to advance?"
          variant="outlined"
          value={loan_amount}
          onChange={handleChange}
          required
          type="number"
          name="loan_amount"
        /> */}

            {/* <label>Your min Advance amount is £3,000</label>
        <label>
          Your max Advance amount is £
          {parseFloat(monthly_rent * 12).toLocaleString()}
        </label> */}
            <Typography>
              Please select a reason why you are taking out this Advance
            </Typography>
            <FormControl>
              <InputLabel id="demo-simple-select-helper-label">
                Reason*
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={reason}
                label="reason"
                name="reason"
                onChange={handleChange}
                displayEmpty
                required
              >
                <MenuItem value="Home improvement/repair">
                  Home improvement/repair
                </MenuItem>
                <MenuItem value="EPC improvements">EPC improvements</MenuItem>
                <MenuItem value="Consolidating debt">
                  Consolidating debt
                </MenuItem>
                <MenuItem value="Car finacing">Car financing</MenuItem>
                <MenuItem value="Special occasion/event">
                  Special occasion/event
                </MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <StageThreeSlider
              setNewAdvance={setNewAdvance}
              loan_amount={parseFloat(loan_amount)}
              monthly_rent={monthly_rent}
              advanceMaxAmount={advanceMaxAmount}
            ></StageThreeSlider>
            <Box sx={styles.displayFlex}>
              <Typography>Advance duration</Typography>
              <Typography sx={styles.rightSideEntry}>
                {loan_term} months
              </Typography>
            </Box>
            <AdvanceDurationSlider
              setNewAdvance={setNewAdvance}
              loan_term={loan_term}
            ></AdvanceDurationSlider>
            <Box sx={styles.displayFlex}>
              <Typography>Your monthly payments will be</Typography>
              <Typography sx={styles.rightSideEntry}>
                £
                {parseFloat(estimated_loan_monthly_payment).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </Typography>
            </Box>
            <Box sx={styles.displayFlex}>
              <Typography>Total repayable is</Typography>{" "}
              {/* <Typography sx={styles.rightSideEntry}>
              £
              {formatFiatNumberTwoDecimals(
                parseFloat(loan_amount_with_arrangement_fee) +
                  estimated_loan_monthly_payment * loan_term -
                  loan_amount,
              )}
              *
            </Typography> */}
              <Tooltip title={generateToolTip()}>
                <p
                  style={{
                    fontWeight: "bold",
                    marginLeft: "auto",
                    paddingRight: "2rem",
                  }}
                  id="my-p-tag"
                >
                  £
                  {formatFiatNumberTwoDecimals(
                    estimated_loan_monthly_payment * loan_term,
                  )}
                  *
                </p>

                {/* {formatFiatNumberTwoDecimals(
                loan_term * estimated_loan_monthly_payment,
              )} */}
              </Tooltip>
            </Box>

            {/* <Box sx={styles.displayFlex}>
            <Typography>APR</Typography>
            <Typography sx={styles.rightSideEntry}>
              {(loan_interest_rate * 100).toFixed(2)}%
            </Typography>
          </Box> */}
            <Box sx={styles.displayFlex}>
              <Typography>Total fees</Typography>
              <Typography sx={styles.rightSideEntry}>
                £
                {formatFiatNumberTwoDecimals(
                  estimated_loan_monthly_payment * loan_term - loan_amount,
                )}
              </Typography>
            </Box>
            <Box sx={styles.displayFlex}>
              <Typography>Monthly Factored fee</Typography>
              <Typography sx={styles.rightSideEntry}>
                {/* {formatFiatNumberTwoDecimals(
                (estimated_loan_monthly_payment * loan_term - loan_amount) / loan_term
              )} */}
                {(
                  (((estimated_loan_monthly_payment * loan_term - loan_amount) /
                    loan_amount) *
                    100) /
                  loan_term
                ).toFixed(2)}
                %{/* {calculateMonthlyFactoredFee()}% */}
              </Typography>
            </Box>

            {errorText?.loan_amount && (
              <Alert severity="error">{errorText?.loan_amount}</Alert>
            )}
            <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
              <Button
                component={Link}
                to={`/new-advance-2/${advanceID}/`}
                sx={{ p: 1.5, width: "50%" }}
                variant="outlined"
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                  <Typography variant="h6">Previous Step</Typography>
                </Box>
              </Button>
              <Button
                component={Link}
                to={`/new-advance-4/${advanceID}/`}
                sx={{ p: 1.5, width: "50%" }}
                variant="contained"
                onClick={handleSubmit}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h6">Next Step</Typography>
                  <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
                </Box>
              </Button>
            </Box>
          </>
        )}
      </GenericContainer>
      )
    </>
  )
}

export default NewAdvance3
