import GenericContainer from "../ui/GenericContainer"
import { Typography, Button, Box } from "@mui/material"
import { Link } from "react-router-dom"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
const ThanksForActivating = () => {
  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Thanks for activating!
      </Typography>
      <Typography variant="b1">
        Your Factored account is now live and you can proceed to create your
        first ever Advance.
      </Typography>
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        component={Link}
        to="/dashboard"
      >
        <Typography variant="h6">
          {" "}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">To Dashboard</Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Typography>
      </Button>
    </GenericContainer>
  )
}
export default ThanksForActivating
