// This page is shown to the user once they create their advance

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Typography } from "@mui/material"
import GenericContainer from "../../ui/GenericContainer"

const AdvanceCreateSuccess = () => {
  useEffect(() => {
    document.title = "Factored - Advance Created"
  }, [])

  const navigate = useNavigate()

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Thank you for creating this Advance!
      </Typography>
      <Typography>
        We'll review your Advance request within the next 2 working days and get
        in touch if we need further information. Please reach out to
        hello@factored.co if you have any questions about your Advance.
      </Typography>
      <Button variant="contained" onClick={() => navigate("/dashboard")}>
        <Typography variant="h6">Back to dashboard</Typography>
      </Button>
    </GenericContainer>
  )
}

export default AdvanceCreateSuccess
