// This page talks about the app and who built it

import React from "react"
import GenericContainer from "../ui/GenericContainer"
const About = () => {
  return (
    <GenericContainer>
      <h2>About Factored</h2>
      <p>
        Sell the rights to your future rental income for a lump sum payment
        today.
      </p>
      <p>This app was developed by Alex H.</p>
      <a target="_blank" rel="noreferrer" href="https://github.com/hal-alex">
        Alex's GitHub can be found here.
      </a>
    </GenericContainer>
  )
}

export default About
