import { FormControl, Box } from "@mui/material"

const GenericContainer = ({
  withFormControl = true,
  dashboard = false,
  children,
}) => {
  let styles = {
    container: (theme) => ({
      background: "white",
      // border: "2px solid #0f1825",
      padding: "3rem",
      margin: "13rem auto",
      maxWidth: "45rem",
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      alignItems: "center",
      boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
    }),
  }

  if (dashboard) {
    styles = {
      container: (theme) => ({
        background: "white",
        // border: "2px solid #0f1825",
        padding: "2rem",
        margin: "8rem auto",
        maxWidth: "90rem",
        boxShadow: "0px 5px 15px rgba(0,0,0,0.1)",
        [theme.breakpoints.down("lg")]: {
          padding: "1rem",
        },
      }),
    }
  }

  if (withFormControl) {
    return (
      <Box sx={styles.container}>
        <FormControl
          variant="standard"
          sx={{
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "2.3rem",
          }}
        >
          {children}
        </FormControl>
      </Box>
    )
  } else if (!withFormControl) {
    return <Box sx={styles.container}>{children}</Box>
  }
}
export default GenericContainer
