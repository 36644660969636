import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isPopUpOpen: false,
}

const popUpSlice = createSlice({
  name: "popUp",
  initialState,
  reducers: {
    openClosePopUp: (state, action) => {
      state.isPopUpOpen = action.payload
    },
  },
})

export const { openClosePopUp } = popUpSlice.actions

export default popUpSlice.reducer
