import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Box, Button, Typography } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import AddFilesImage from "../assets/my_documents.svg"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import DoneIcon from "@mui/icons-material/Done"
import DemoStepper from "../components/DemoStepper"

const InfoStepper = () => {
  const styles = {
    checkBoxContainer: {
      display: "flex",
      alignItems: "center",
    },
    iconMargin: {
      marginRight: "0.2rem",
    },
  }

  useEffect(() => {
    document.title = "Factored - Start Verification"
  }, [])

  return (
    <GenericContainer>
      <DemoStepper stepNumber={0}></DemoStepper>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Before you begin verifying your identity
      </Typography>
      <img src={AddFilesImage} style={{ width: "7rem", margin: "0 auto" }} />
      <Typography variant="b1">
        Please make sure you have the following at hand:
      </Typography>
      <Box style={styles.checkBoxContainer}>
        <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>
        Valid government-issued photo ID e.g. Driving Licence, National ID,
        Passport, Resident Permit
      </Box>
      <Box style={styles.checkBoxContainer}>
        <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>Recent
        proof of address e.g. utility bill or bank statement issued within 3
        months
      </Box>
      <Box style={styles.checkBoxContainer}>
        <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>Please
        make sure you’re on a device with photo and video capabilities
      </Box>
      <Button
        component={Link}
        to="/identity-verification"
        sx={{ p: 1.5 }}
        variant="contained"
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Next Step</Typography>
          <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
        </Box>
      </Button>
    </GenericContainer>
  )
}

export default InfoStepper
