import { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Checkbox,
  Alert,
} from "@mui/material"

import GenericContainer from "../ui/GenericContainer"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  emailAddress: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email address is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Password needs to have at least one upper case letter")
    .matches(/\d/, "Password needs to have at least one number"),
  acceptTermsConditions: yup
    .boolean()
    .oneOf(
      [true],
      "Need to accept Terms & Conditions and Privacy Policy before creating an account",
    )
    .required(
      "Need to accept Terms & Conditions and Privacy Policy before creating an account",
    ),
})

const Register = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Factored - Register"
  }, [])

  const [passwordShow, setPasswordShow] = useState(false)

  const [regError, setRegError] = useState("")
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const [formMessage, setFormMessage] = useState("")

  const [regFormData, setRegFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    password: "",
    acceptTermsConditions: false,
  })

  const [errors, setErrors] = useState({})

  const newErrors = {}

  const API_URL = "api/user/create/"

  const makeAPIRequest = async () => {
    try {
      await axios.post(API_URL, {
        email: regFormData.emailAddress,
        title: regFormData.title,
        first_name: regFormData.firstName,
        last_name: regFormData.lastName,
        password: regFormData.password,
      })
      // console.log(response)
      setRegError("")
      setServerErrorMessage("")
      setFormMessage(
        "Account created successfully! Please check your email for account activation link.",
      )
      // The timeout navigates to the login page if account was created
      setTimeout(() => {
        navigate("/check-your-email")
      }, 3000)
    } catch (error) {
      // console.log(error.response.data)
      setServerErrorMessage(error.response.data)
      // setRegError(error.response.data)
    }
  }

  const handleRegisterFormSubmit = async (e) => {
    e.preventDefault()
    // setRegError("")

    try {
      await schema.validate(regFormData, { abortEarly: false })
      makeAPIRequest()
    } catch (error) {
      const validationErrors = {}
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message
      })

      setErrors(validationErrors)
    }
  }

  const handleChange = (e) => {
    if (e.target.name === "acceptTermsConditions") {
      setRegFormData({
        ...regFormData,
        acceptTermsConditions: !regFormData.acceptTermsConditions,
      })
    } else {
      setErrors(newErrors)
      setRegFormData({ ...regFormData, [e.target.name]: e.target.value })
    }
  }

  const displayServerErrors = () => {
    return Object.entries(serverErrorMessage).map((itemKey) => {
      let title = itemKey[0]
      if (itemKey[0].includes("_")) {
        title = itemKey[0].replace(/_/g, " ")
      }
      return (
        <Alert severity="error">
          {title.charAt(0).toUpperCase() + title.slice(1)} - {itemKey[1][0]}
        </Alert>
      )
    })
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Register New Account
      </Typography>
      <FormControl>
        <InputLabel id="demo-simple-select-helper-label">Title*</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={regFormData.title}
          label="title"
          name="title"
          onChange={handleChange}
          error={errors.title ? true : false}
          helperText={errors.title && errors.title}
        >
          <MenuItem value="Mrs">Mrs</MenuItem>
          <MenuItem value="Miss">Miss</MenuItem>
          <MenuItem value="Ms">Ms</MenuItem>
          <MenuItem value="Mr">Mr</MenuItem>
        </Select>
      </FormControl>
      <TextField
        error={errors.firstName ? true : false}
        helperText={errors.firstName && `${errors.firstName}`}
        label="First Name"
        variant="outlined"
        required
        type="text"
        name="firstName"
        value={regFormData.firstName}
        onChange={handleChange}
      />
      <TextField
        label="Last Name"
        variant="outlined"
        value={regFormData.lastName}
        onChange={handleChange}
        required
        type="text"
        name="lastName"
        error={errors.lastName ? true : false}
        helperText={errors.lastName && `${errors.lastName}`}
      />
      <TextField
        label="Email Address"
        variant="outlined"
        value={regFormData.emailAddress}
        onChange={handleChange}
        required
        type="email"
        name="emailAddress"
        error={errors.emailAddress ? true : false}
        helperText={errors.emailAddress && `${errors.emailAddress}`}
      />
      <TextField
        id="outlined-password-input"
        required
        label="Password"
        name="password"
        onChange={handleChange}
        error={errors.password ? true : false}
        type={passwordShow ? "text" : "password"}
        autoComplete="current-password"
        helperText={errors.password && `${errors.password}`}
        // helperText={
        //   errors.password
        //     ? `${errors.password}`
        //     : `Password needs to be at least 8 characters long,
        //   contain an upper case letter and at least 1 number`
        // }
        // FormHelperTextProps={{
        //   style: {
        //     color: errors.password ? "#d32f2f" : "black",
        //     fontWeight: "500",
        //     marginLeft: errors.password ? "14px" : "2px",
        //   },
        // }}
      />
      <Typography>
        Password needs to be at least 8 characters long, contain an upper case
        letter and at least 1 number
      </Typography>
      <Typography
        variant="p"
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: "2px",
        }}
        onClick={() => setPasswordShow(!passwordShow)}
      >
        {passwordShow ? "hide password" : "show password"}
      </Typography>
      <Box>
        <Checkbox
          checked={regFormData.acceptTermsConditions}
          onChange={handleChange}
          name="acceptTermsConditions"
          sx={{
            margin: "0px",
            padding: "0px",
            "& .MuiSvgIcon-root": { fontSize: 24 },
          }}
        />
        <label>
          I accept Factored's{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.factored.co/legal"
          >
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.factored.co/legal"
          >
            Privacy Policy
          </a>
        </label>
      </Box>
      {errors.acceptTermsConditions && (
        <Alert severity="error">{errors.acceptTermsConditions}</Alert>
      )}
      {serverErrorMessage && displayServerErrors()}
      {regError ? <Alert severity="error">{regError}</Alert> : ""}
      {formMessage ? <Alert severity="success">{formMessage}</Alert> : ""}
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handleRegisterFormSubmit}
      >
        <Typography variant="h6">Create Account</Typography>
      </Button>
    </GenericContainer>
  )
}

export default Register
