// This is the first screen that the user sees when they land on app.[domainname].com
// Or this is also the screen the user sees when they have logged out

import React from "react"
import { Link } from "react-router-dom"

import { Button, Typography } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
const Homepage = () => {
  return (
    <GenericContainer withFormControl={false}>
      <Typography variant="h2">Welcome to Factored</Typography>
      <Link to="/register">
        <Button variant="contained" sx={{ width: "12rem" }}>
          <Typography variant="h6">Register </Typography>
        </Button>
      </Link>
      <Link to="/login">
        <Button variant="contained" sx={{ width: "12rem" }}>
          <Typography variant="h6">Log In</Typography>
        </Button>
      </Link>
    </GenericContainer>
  )
}

export default Homepage
