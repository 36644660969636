// This is a default page that the user sees if they navigate to a page
// that doesn't exist

import { useEffect } from "react"
import GenericContainer from "../ui/GenericContainer"
const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Factored - Page Not Found"
  }, [])

  return (
    <GenericContainer>
      <h2>Page Not Found</h2>
      <p>The page you are looking for could not be found.</p>
    </GenericContainer>
  )
}

export default NotFoundPage
