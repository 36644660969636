import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { useSelector, useDispatch } from "react-redux"
import { openClosePopUp } from "../redux/popUpSlice"
import CloseIcon from "@mui/icons-material/Close"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export default function AlertDialog() {
  const { isPopUpOpen } = useSelector((store) => store.popUps)
  const [open, setOpen] = React.useState(isPopUpOpen)
  const dispatch = useDispatch()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    // setOpen(false)

    dispatch(openClosePopUp(false))
  }

  return (
    <Dialog
      open={isPopUpOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon
            onClick={handleClose}
            sx={{ cursor: "pointer" }}
          ></CloseIcon>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">
          Before you can create your first Advance, you need to complete our
          verification checks. This is a one-off process and doesn't take too
          long to complete.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: "1rem" }}>
        <Button
          sx={{ p: 1.5, m: "0 auto" }}
          variant="contained"
          component={Link}
          to={"/verification-info"}
          onClick={handleClose}
        >
          <Typography variant="h6">Start Verification</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
