// This is the sticky navbar that is always shown in the UI

import React from "react"
import { NavLink, Link } from "react-router-dom"
import LOGO from "../assets/Signature2.png"
import { useNavigate } from "react-router-dom"
import PersonIcon from "@mui/icons-material/Person"
import { Button, Typography } from "@mui/material"
import { useSelector } from "react-redux"
const Navbar = () => {
  const { isButtonDisplayed } = useSelector((store) => store.header)

  const navigate = useNavigate()

  // This checks if there is a local auth token present
  const getLocalToken = () => {
    // console.log("getLocalToken hit")
    return window.localStorage.getItem("factored_token")
  }

  // This handles the log out and removes the auth token from local storage
  const handleLogout = () => {
    window.localStorage.removeItem("factored_token")
    navigate("/")
  }

  return (
    <header className="main-header">
      <div className="header-logo">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.factored.co/"
          className="main-header__brand"
        >
          <img src={LOGO} alt="" />
        </a>
      </div>
      <nav className="main-navbar">
        <ul className="main-navbar__items">
          {/* <ul className="nav-bar-links"> */}
          {/* This ternary conditionally displays two sets of items in the navbar

          If there is no local auth token, we display the option to register

          If there is a local auth token, then we display the profile and
          log out options
          */}
          {getLocalToken() ? (
            <>
              {/* {isButtonDisplayed && (
                <li>
                  <Button
                    sx={{ p: 1 }}
                    variant="outlined"
                    component={Link}
                    to={"/verification-info"}
                  >
                    <Typography variant="h6">Verify Your Identity</Typography>
                  </Button>
                </li>
              )} */}

              <li className="main-navbar__item">
                <NavLink to="/dashboard">Dashboard</NavLink>
              </li>
              <li onClick={handleLogout} className="main-navbar__item">
                <NavLink to="/">Log Out</NavLink>
              </li>
              <li className="main-navbar__item">
                <NavLink to="/profile">
                  {<PersonIcon fontSize="large"></PersonIcon>}
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li className="main-navbar__item">
                <NavLink to="/register">Register</NavLink>
              </li>
              <li className="main-navbar__item">
                <NavLink to="/login">Log In</NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default Navbar
