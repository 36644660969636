// This component allows the user to enter their address history
// It also displays the previously entered addresses if the user
// has not successfully passed the address history verification

// This component also calculates the amount of months you spent at
// each address and then the total amount of months we have on file

import React from "react"
import { useState, useEffect } from "react"
import axios from "axios"
axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const AddressHistoryInput = () => {
  const [userDetails, setUserDetails] = useState({})

  // Initial useEffect to check for the local auth token,
  // fetches the user details and the address history entries
  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
      getAddressHistory()
    }
  }, [])

  // This fetches the user details
  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      console.log("API response", response.data)
      setUserDetails(response.data)
      setDuration(response.data.total_address_duration)
    } catch (error) {
      console.log("context error", error)
    }
  }

  // This sets the total duration of all addresses on file
  const [duration, setDuration] = useState(userDetails.total_address_duration)

  // Default starting values for each address entry
  const addressTemplate = {
    id: 0,
    firstLine: "",
    secondLine: "",
    postcode: "",
    townOrCity: "",
    country: "",
    startDate: "",
    endDate: "",
    addressDuration: 0,
  }

  const [addressHistory, setAddressHistory] = useState([])

  // To calculate the amount of months spent at each address
  // The below months list is used for helping the calculation
  const months = [
    { month: "Jan", value: "01" },
    { month: "Feb", value: "02" },
    { month: "Mar", value: "03" },
    { month: "Apr", value: "04" },
    { month: "May", value: "05" },
    { month: "Jun", value: "06" },
    { month: "Jul", value: "07" },
    { month: "Aug", value: "08" },
    { month: "Sep", value: "09" },
    { month: "Oct", value: "10" },
    { month: "Nov", value: "11" },
    { month: "Dec", value: "12" },
  ]

  // This fetches the address history of the user
  const getAddressHistory = async () => {
    try {
      const response = await axios.get("api/addresshistory/")
      // console.log(response.data)
      // The below iterates over the all addresses on file
      // And displays the starting and ending dates for each address
      let new_addresses = response.data.map((address) => {
        // console.log(address)
        const { end_date, start_date } = address
        if (end_date.length === 8 && start_date.length === 8) {
          const newEndDate = end_date.split(" ")
          const foundEndMounth = months.find(
            (element) => element.month == newEndDate[0],
          ).value
          const newStartDate = start_date.split(" ")
          const foundStartMounth = months.find(
            (element) => element.month == newStartDate[0],
          ).value
          return {
            ...address,
            end_date: `${newEndDate[1]}-${foundEndMounth}`,
            start_date: `${newStartDate[1]}-${foundStartMounth}`,
          }
        } else {
          return address
        }
      })
      // console.log(new_addresses)
      setAddressHistory(new_addresses)
    } catch (error) {
      console.log(error)
    }
  }

  // This either creates a new address or updates an existing one
  const createUpdateAddressHistory = async (address) => {
    // e.preventDefault()
    // We break down the fields of an address for easier use later
    const {
      id,
      first_line_address,
      second_line_address,
      postcode,
      town_or_city,
      country,
      start_date,
      end_date,
    } = address
    const duration = calculateMonths(end_date, start_date)
    // The initial value of an ID is 0, this means that
    // the below is statement will trigger and use a POST
    // request to create a new address for the user
    if (!id) {
      try {
        const response = await axios.post("api/addresshistory/", {
          first_line_address: first_line_address,
          second_line_address: second_line_address,
          postcode: postcode,
          town_or_city: town_or_city,
          country: country,
          start_date: start_date,
          end_date: end_date,
          duration: duration,
        })

        // console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    // This other if statement will check if the ID is a non-0 ID
    // As existing addresses will have an actual, unique ID,
    // then the below if statement will trigger to update an existing address
    // We use PATCH for this update to happen
    else if (id) {
      try {
        const response = await axios.patch(`api/addresshistory/${id}/`, {
          first_line_address: first_line_address,
          second_line_address: second_line_address,
          postcode: postcode,
          town_or_city: town_or_city,
          country: country,
          start_date: start_date,
          end_date: end_date,
          duration: duration,
        })
        // console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }

  // This deletes the address on file
  const deleteSingleAddress = async (id) => {
    try {
      const response = await axios.delete(`api/addresshistory/${id}/`)
    } catch (error) {
      console.log(error)
    }
  }

  // This calculates the amount of months spent at the particular address
  const calculateMonths = (endDate, startDate) => {
    if (endDate && startDate) {
      let endAddressYear = parseInt(endDate.split("-")[0])
      // console.log(endAddressYear)
      let endAddressMonth = parseInt(endDate.split("-")[1])
      // console.log(endAddressMonth)
      let startAddressYear = parseInt(startDate.split("-")[0])
      // console.log(startAddressYear)
      let startAddressMonth = parseInt(startDate.split("-")[1])
      // console.log(startAddressMonth)
      let differenceMonths =
        endAddressMonth -
        startAddressMonth +
        12 * (endAddressYear - startAddressYear)

      // console.log(differenceMonths)

      return differenceMonths
    }

    return 0
  }

  // This useEffect is triggered whenever there is a new address added
  // We want it to trigger because we want to update the total months spent
  // And if the total is over 36 months
  useEffect(() => {
    for (let index = 0; index < addressHistory.length; index++) {
      const singleAddress = addressHistory[index]
      const { endDate, startDate } = singleAddress
      let months = calculateMonths(endDate, startDate)
    }
  }, [addressHistory])

  // This handles the form change
  const handleChange = (index, e) => {
    let data = [...addressHistory]
    data[index][e.target.name] = e.target.value
    setAddressHistory(data)
  }

  // This submits the form and reloads the page
  const submitForm = (e) => {
    e.preventDefault()
    window.location.reload(false)
    // if (duration >= 36) {
    //     console.log("address hit")
    // }
  }

  // This creates an empty field for a new address
  const addMoreAddressFields = (e) => {
    e.preventDefault()
    setAddressHistory([...addressHistory, addressTemplate])
  }

  return (
    <div>
      <p>
        We require at least 3 years of address history for a soft credit check
      </p>
      <p>{`So far, we have address history for you for ${duration} months`}</p>
      {(duration >= 36 && <p>Address history is good</p>) || (
        <p>Please add more addresses</p>
      )}

      <form className="address-verification-form" onSubmit={submitForm}>
        {addressHistory.map((address, index) => {
          const {
            id,
            first_line_address,
            second_line_address,
            postcode,
            town_or_city,
            country,
            start_date,
            end_date,
          } = address
          let addressDurationHist = 0
          if (end_date && start_date) {
            addressDurationHist = calculateMonths(end_date, start_date)
          }
          return (
            <div className="address-verification-form-address" key={index}>
              <h4>{id.length > 4 ? "Address on Record" : "New Address"}</h4>
              <label>First Address Line*</label>
              <input
                type="text"
                name="first_line_address"
                value={first_line_address}
                onChange={(e) => handleChange(index, e)}
              />
              <label>Second Address Line*</label>
              <input
                type="text"
                name="second_line_address"
                value={second_line_address}
                onChange={(e) => handleChange(index, e)}
              />
              <label>Post code*</label>
              <input
                type="text"
                name="postcode"
                value={postcode}
                onChange={(e) => handleChange(index, e)}
              />
              <label>Town/City*</label>
              <input
                type="text"
                name="town_or_city"
                value={town_or_city}
                onChange={(e) => handleChange(index, e)}
              />
              <label>Country*</label>
              <input
                type="text"
                name="country"
                value={country}
                onChange={(e) => handleChange(index, e)}
              />
              <label>Start Date*</label>
              <input
                type="month"
                name="start_date"
                value={start_date}
                onChange={(e) => handleChange(index, e)}
              />
              <label>End Date*</label>
              <input
                type="month"
                name="end_date"
                value={end_date}
                onChange={(e) => handleChange(index, e)}
              />
              <p>*Required field</p>
              {addressDurationHist
                ? `You have spent ${addressDurationHist} 
                                    months at this address`
                : ""}
              <div className="two-button-container">
                <button
                  className="btn-secondary"
                  onClick={() => deleteSingleAddress(id)}
                >
                  Delete This Address
                </button>
                <button
                  className="btn-secondary"
                  onClick={() => createUpdateAddressHistory(address)}
                >
                  {id ? "Update" : "Submit"} This Address
                </button>
              </div>
            </div>
          )
        })}
        <button className="btn-secondary" onClick={addMoreAddressFields}>
          Add Another Address
        </button>
      </form>
    </div>
  )
}

export default AddressHistoryInput
