import GenericContainer from "../ui/GenericContainer"
import DragDrop from "./advance_creation_stages/DragDrop"

const Experimental = () => {
  return (
    <GenericContainer>
      Experimental
      <DragDrop></DragDrop>
    </GenericContainer>
  )
}
export default Experimental
