import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Navbar from "./components/Navbar"
import Homepage from "./pages/Homepage"
import Dashboard from "./pages/Dashboard"
import Login from "./pages/Login"
import Register from "./pages/Register"
import ForgotPassword from "./pages/ForgotPassword"
import Profile from "./pages/Profile"
import AddressVerification from "./pages/AddressVerification"
import IDVerification from "./pages/IDVerification"
import IndividualAdvance from "./pages/IndividualAdvance"
// import NewAdvance1 from "./pages/advance_creation_stages/NewAdvance1"
import NewAdvance1 from "./pages/advance_creation_stages/NewAdvance1"
import NewAdvance2 from "./pages/advance_creation_stages/NewAdvance2"
import NewAdvance3 from "./pages/advance_creation_stages/NewAdvance3"
import NewAdvance4 from "./pages/advance_creation_stages/NewAdvance4"
import NewAdvance5 from "./pages/advance_creation_stages/NewAdvance5"
import NewAdvanceConf from "./pages/advance_creation_stages/NewAdvanceConf"
import AdvanceCreateSuccess from "./pages/advance_creation_stages/AdvanceCreateSuccess"
import PasswordReset from "./pages/PasswordReset"
import About from "./pages/About"
import NotFoundPage from "./pages/NotFoundPage"
import ProofOfAddress from "./pages/ProofOfAddress"
import InfoStepper from "./pages/InfoStepper"
import AddContactNumber from "./pages/AddContactNumber"
import SteppersComplete from "./pages/SteppersComplete"
import NewAdvancePreStage1 from "./pages/advance_creation_stages/NewAdvancePreStage1"
import ActivateAccount from "./pages/ActivateAccount"
import ThanksForActivating from "./pages/ThanksForActivating"
import ActivateAccountEmailLink from "./pages/ActivateAccountEmailLink"
import CheckEmail from "./pages/CheckEmail"
import Experimental from "./pages/Experimental"
import RegisterNew from "./pages/RegisterNew"

function App() {
  return (
    <BrowserRouter>
      <Navbar></Navbar>
      <Routes>
        <Route path="/" element={<Homepage></Homepage>}></Route>
        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/register" element={<RegisterNew></RegisterNew>}></Route>
        <Route path="/dashboard" element={<Dashboard></Dashboard>}></Route>
        <Route
          path="/forgotpassword"
          element={<ForgotPassword></ForgotPassword>}
        ></Route>
        <Route path="/profile" element={<Profile></Profile>}></Route>
        <Route
          path="/address-history"
          element={<AddressVerification></AddressVerification>}
        ></Route>
        <Route
          path="/identity-verification"
          element={<IDVerification></IDVerification>}
        ></Route>
        <Route
          path="/advances/:advanceID"
          element={<IndividualAdvance></IndividualAdvance>}
        ></Route>
        <Route
          path="/new-advance-info/:advanceID"
          element={<NewAdvancePreStage1></NewAdvancePreStage1>}
        ></Route>
        {/* <Route
          path="/new-advance-1/:advanceID"
          element={<NewAdvance1></NewAdvance1>}
        ></Route> */}
        <Route
          path="/new-advance-1/:advanceID"
          element={<NewAdvance1></NewAdvance1>}
        ></Route>
        <Route
          path="/new-advance-2/:advanceID"
          element={<NewAdvance2></NewAdvance2>}
        ></Route>
        <Route
          path="/new-advance-3/:advanceID"
          element={<NewAdvance3></NewAdvance3>}
        ></Route>
        <Route
          path="/new-advance-4/:advanceID"
          element={<NewAdvance4></NewAdvance4>}
        ></Route>
        <Route
          path="/new-advance-5/:advanceID"
          element={<NewAdvance5></NewAdvance5>}
        ></Route>
        <Route
          path="/new-advance-confirmation/:advanceID"
          element={<NewAdvanceConf></NewAdvanceConf>}
        ></Route>
        <Route
          path="/new-advance-success"
          element={<AdvanceCreateSuccess></AdvanceCreateSuccess>}
        ></Route>
        <Route
          path="/password-reset/:resetToken"
          element={<PasswordReset></PasswordReset>}
        ></Route>
        <Route
          path="/current-address-verification"
          element={<ProofOfAddress></ProofOfAddress>}
        ></Route>
        <Route path="/about-factored-app" element={<About></About>}></Route>
        <Route path="*" element={<NotFoundPage></NotFoundPage>}></Route>
        <Route
          path="/verification-info"
          element={<InfoStepper></InfoStepper>}
        ></Route>
        <Route
          path="/add-contact-number"
          element={<AddContactNumber></AddContactNumber>}
        ></Route>
        <Route
          path="/verification-complete"
          element={<SteppersComplete></SteppersComplete>}
        ></Route>
        <Route
          path="/activate-account"
          element={<ActivateAccount></ActivateAccount>}
        ></Route>
        <Route
          path="/thanks-for-activating"
          element={<ThanksForActivating></ThanksForActivating>}
        ></Route>
        <Route
          path="/activate-account/:activationToken"
          element={<ActivateAccountEmailLink></ActivateAccountEmailLink>}
        ></Route>
        <Route
          path="/check-your-email"
          element={<CheckEmail></CheckEmail>}
        ></Route>
        <Route
          path="/experimental"
          element={<Experimental></Experimental>}
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
