import { useEffect, useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import DemoStepper from "../components/DemoStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"

import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import GenericContainer from "../ui/GenericContainer"
axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const SteppersComplete = () => {
  const { idOfTheAdvance } = useSelector((store) => store.advanceKycFlow)
  // console.log(idOfTheAdvance)
  const navigate = useNavigate()
  const [advanceInProgress, setAdvanceInProgress] = useState("")

  useEffect(() => {
    document.title = "Factored - Verification Complete"
    const token = window.localStorage.getItem("factored_token")
    const localAdvance = window.localStorage.getItem("advanceInProgress")
    if (localAdvance) {
      setAdvanceInProgress(localAdvance)
    }

    if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
    } else if (!token) {
      navigate("/")
    }
  }, [])

  const createNewAdvance = async () => {
    try {
      const response = await axios.post("api/advances/create/")
      // console.log(newAdvanceId)
      // console.log("API response", response.data)
      // setNewAdvanceId(response.data.id)
      navigate(`/new-advance-1/${response.data.id}/`)
    } catch (error) {
      console.log("context error", error)
    }
  }

  const navigateToAdvanceConf = () => {
    navigate(`/new-advance-confirmation/${advanceInProgress}/`)
  }

  return (
    <GenericContainer>
      <DemoStepper stepNumber={4}></DemoStepper>
      <Typography sx={{ textAlign: "center" }} variant="h2">
        Thanks for verifying!
      </Typography>
      <Typography variant="p">
        {advanceInProgress
          ? `You're all set to continue 
          editing and submit Advance with the ID ${advanceInProgress}`
          : "You’re all set to create your first Advance"}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to="/dashboard"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Back To Dashboard</Typography>
          </Box>
        </Button>
        <Button
          // component={Link}
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          onClick={() =>
            advanceInProgress ? navigateToAdvanceConf() : createNewAdvance()
          }
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">
              {advanceInProgress
                ? `Back To ${advanceInProgress}`
                : "Create Advance"}
            </Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default SteppersComplete
