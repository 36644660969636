// This page resets the user password after they received the
// password reset email

import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import { Button, Typography, TextField, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
const PasswordReset = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Factored - Password Reset"
  }, [])

  const resetToken = useParams()
  // console.log(resetToken.resetToken)

  const [passwordShow, setPasswordShow] = useState(false)

  const [pwResetFormError, setPwResetFormError] = useState("")
  const [formMessage, setFormMessage] = useState("")
  const [pwResetFormData, setPwResetFormData] = useState({
    password: "",
  })

  const handlePwResetFormSubmit = (e) => {
    e.preventDefault()
    setPwResetFormError("")

    if (pwResetFormData.password.length < 8) {
      return setPwResetFormError(
        "Password needs to be at least 8 characters long",
      )
    } else if (
      pwResetFormData.password === pwResetFormData.password.toLowerCase()
    ) {
      return setPwResetFormError(
        "Password need to have at least one upper case letter",
      )
    } else if (!/\d/g.test(pwResetFormData.password)) {
      return setPwResetFormError("Password needs to have at least one number")
    }

    const resetPassword = async () => {
      try {
        await axios.post("api/user/resetpassword/", {
          password: pwResetFormData.password,
          token: resetToken.resetToken,
        })
        // console.log("API response", response.data)
        setFormMessage("You have successfully changed your password!")
        setTimeout(() => {
          navigate("/login")
        }, 3000)
      } catch (error) {
        // console.log(error.response)
        setPwResetFormError(error.response.data.detail)
      }
    }
    resetPassword()
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Reset Your Password
      </Typography>
      <TextField
        id="outlined-password-input"
        required
        label="Password"
        name="password"
        onChange={(e) => setPwResetFormData({ password: e.target.value })}
        type={passwordShow ? "text" : "password"}
        autoComplete="current-password"
        helperText="Password needs to be at least 8 characters long, 
          contain an upper case letter and at least 1 number"
        FormHelperTextProps={{
          style: {
            color: "black",
            fontWeight: "500",
            marginLeft: "2px",
          },
        }}
      />
      <Typography
        variant="p"
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: "2px",
        }}
        onClick={() => setPasswordShow(!passwordShow)}
      >
        {passwordShow ? "hide password" : "show password"}
      </Typography>
      {pwResetFormError ? (
        <Alert severity="error">{pwResetFormError}</Alert>
      ) : (
        ""
      )}
      {formMessage && <Alert severity="success">{formMessage}</Alert>}
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handlePwResetFormSubmit}
      >
        <Typography variant="h6">Reset Password</Typography>
      </Button>
    </GenericContainer>
  )
}

export default PasswordReset
