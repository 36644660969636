// This page allows the user to log in

import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { Button, Typography, TextField, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"

const Login = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = "Factored - Log In"
  }, [])

  const [passwordShow, setPasswordShow] = useState(false)

  const [loginFormError, setLoginFormError] = useState("")
  const [formMessage, setFormMessage] = useState("")
  // The below is the default state for the form at the start
  const [loginFormData, setLoginFormData] = useState({
    emailAddress: "",
    password: "",
  })

  const API_URL = "api/user/token/"

  const makeAPIRequest = async () => {
    try {
      const response = await axios.patch(API_URL, {
        email: loginFormData.emailAddress,
        password: loginFormData.password,
      })
      // console.log(response)
      setLoginFormError("")
      setFormMessage("Logged in successfully!")
      // This sets the auth token in local storage
      // The auth token accompanies each authenticated request to the backend
      window.localStorage.setItem("factored_token", response.data.token)
      console.log(response.data.is_email_verified)
      if (!response.data.is_email_verified) {
        navigate("/activate-account")
      } else {
        navigate("/dashboard")
      }
      // The timeout navigates the user to the dashboard
      // once their login is a success
      // setTimeout(() => {
      //   navigate("/dashboard")
      // }, 1000)
    } catch (error) {
      // console.log(error)
      setLoginFormError(error.message)
    }
  }

  const handleLoginFormSubmit = (e) => {
    e.preventDefault()
    setLoginFormError("")
    // The below validates the form entries before making request
    // to the backend
    if (!loginFormData.emailAddress) {
      return setLoginFormError("Email address is required")
    } else if (
      !loginFormData.emailAddress.includes("@") ||
      !loginFormData.emailAddress.includes(".")
    ) {
      return setLoginFormError("Email address is must be valid")
    } else if (!loginFormData.password) {
      return setLoginFormError("Password is required")
    } else if (loginFormData.password.length < 8) {
      return setLoginFormError(
        "Password needs to be at least 8 characters long",
      )
    } else if (
      loginFormData.password === loginFormData.password.toLowerCase()
    ) {
      return setLoginFormError(
        "Password need to have at least one upper case letter",
      )
    } else if (!/\d/g.test(loginFormData.password)) {
      return setLoginFormError("Password needs to have at least one number")
    } else {
      // console.log("API request made")
      makeAPIRequest()
    }
  }

  const handleChange = (e) => {
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value })
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Access Your Account
      </Typography>
      <TextField
        id="outlined-basic"
        label="Email Address"
        variant="outlined"
        value={loginFormData.emailAddress}
        onChange={handleChange}
        required
        type="email"
        name="emailAddress"
      />
      <TextField
        id="outlined-password-input"
        required
        label="Password"
        name="password"
        onChange={handleChange}
        type={passwordShow ? "text" : "password"}
        autoComplete="current-password"
        helperText="Password needs to be at least 8 characters long, 
          contain an upper case letter and at least 1 number"
        FormHelperTextProps={{
          style: {
            color: "black",
            fontWeight: "500",
            marginLeft: "2px",
          },
        }}
      />
      <Typography
        variant="p"
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          marginLeft: "2px",
        }}
        onClick={() => setPasswordShow(!passwordShow)}
      >
        {passwordShow ? "hide password" : "show password"}
      </Typography>

      <Link to="/forgotpassword">Forgot password?</Link>
      {loginFormError ? <Alert severity="error">{loginFormError}</Alert> : ""}

      {formMessage ? <Alert severity="success">{formMessage}</Alert> : ""}

      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handleLoginFormSubmit}
      >
        <Typography variant="h6">Log In</Typography>
      </Button>
    </GenericContainer>
  )
}

export default Login
