import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isButtonDisplayed: true,
}

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    displayButton: (state, action) => {
      state.isButtonDisplayed = action.payload
    },
  },
})

export const { displayButton } = headerSlice.actions

export default headerSlice.reducer
