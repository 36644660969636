// For better UX, the advance creation process is broken down into 4 steps
// These 4 steps are 4 different pages linked in a logical chain

// This is the final stage for the advance creation
// It allows the user to review the details of the new advance
// Also to accept the terms and condition of the platform

import { useNavigate, useParams, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import ConfAdvancePopup from "../../components/ConfAdvancePopup"
import axios from "axios"
import {
  Box,
  Button,
  Typography,
  Alert,
  Checkbox,
  Tooltip,
  tooltipClasses,
} from "@mui/material"
import { styled } from "@mui/material/styles"

import EditIcon from "@mui/icons-material/Edit"
import AdvanceStepper from "../../components/AdvanceStepper"
import ExperimentPopup from "../../components/ExperimentPopup"
import { useDispatch } from "react-redux"
import { openClosePopUp } from "../../redux/popUpSlice"
import GenericContainer from "../../ui/GenericContainer"
import { formatFiatNumberTwoDecimals } from "../../utils/common"

import dayjs from "dayjs"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const NewAdvanceConf = () => {
  const dispatch = useDispatch()

  const styles = {
    summaryContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
  }

  // We fetch the advance ID from the URL using the below hook
  let { advanceID } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const [acceptTerms, setAcceptTerms] = useState(false)

  const [errorText, setErrorText] = useState("")
  const [rentAgreementFiles, setRentAgreementFiles] = useState([])
  const [rentProtectionFiles, setRentProtectionFiles] = useState([])
  // Initial useEffect to check for the local auth token
  // and fetching of a single advance

  const getAllDocuments = async () => {
    setIsLoading(true)
    const { data } = await axios.get(
      `api/advance-documents/manage/${advanceID}/`,
    )

    setRentAgreementFiles(
      data.filter((file) => file.type_of_document === "rent_agreement"),
    )
    setRentProtectionFiles(
      data.filter((file) => file.type_of_document === "rent_protection"),
    )

    try {
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    document.title = `Factored - Advance ${advanceID}`
    const token = window.localStorage.getItem("factored_token")
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
      getSingleAdvance()
      getAllDocuments()
    } else if (!token) {
      navigate("/")
    }
  }, [])

  const [userDetails, setUserDetails] = useState({})

  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // console.log("API response", response.data)
      setUserDetails(response.data)
    } catch (error) {
      console.log("context error", error)
    }
  }
  const {
    is_identity_verified,
    is_address_verified,
    mobile_number,
    is_mobile_number_verified,
  } = userDetails

  // const isUserVerified =
  //   is_identity_verified && is_address_verified && mobile_number.length >= 1

  const checkIfUserIsVerified = () => {
    if (
      (is_identity_verified === true) &
      (is_address_verified === true) &
      (is_mobile_number_verified === true)
    ) {
      return true
    } else {
      return false
    }
  }

  // console.log({ is_identity_verified })
  // console.log({ is_address_verified })
  // console.log({ mobile_number })
  // console.log({ isUserVerified })

  // This fetches the advance using the ID from the useParams hook
  const getSingleAdvance = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/advances/${advanceID}/`)
      // console.log(data)
      setNewAdvance(data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const [newAdvance, setNewAdvance] = useState({})

  // This breaks down most of the fields of the advance so that
  // it would be easier to use them in JSX
  const {
    description,
    first_line_address,
    second_line_address,
    postcode,
    town_or_city,
    country,
    reason,
    loan_amount,
    loan_term,
    estimated_loan_monthly_payment,
    monthly_rent,
    advance_monthly_factored_fee,
    mortgaged_property,
    total_income_from_other_sources,
    monthly_mortgage_amount,
    mortgage_fixed_or_variable,
    date_of_fixed_mortgage_expiry,
    name_on_bank_account,
    bank_account_number,
    sort_code_bank_account,
  } = newAdvance

  const handleTickbox = () => {
    setAcceptTerms(!acceptTerms)
    setErrorText("")
  }

  // This sends the final patch request to the backend
  // And the backend knows that the user is submitting this loan for
  // the final time because is_submitting_loan is true
  const createAdvanceAPICall = async () => {
    try {
      const response = await axios.patch(`api/advances/${advanceID}/`, {
        is_submitting_loan: true,
      })
      // console.log(response)
    } catch (error) {
      console.log(error)
    }
  }

  // This checks if the user has ticked the terms and conditions checkbox
  const handleSubmit = () => {
    if (!acceptTerms) {
      setErrorText("You must accept terms before creating this Advance")
    } else {
      createAdvanceAPICall()
      navigate("/new-advance-success")
    }
  }
  // If the page is loading, we display this placeholder,
  // and wait until the advance details render
  // if (isLoading) {
  //   return (
  //     <div className="generic-container">
  //       <h2>Please wait...</h2>
  //     </div>
  //   )
  // }

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      border: `2px ${theme.palette.primary.main} solid`,
      fontSize: 16,
      width: "200%",
    },
  }))

  const openPopup = () => {
    // dispatch(setInitialAdvance(advanceID))
    dispatch(openClosePopUp(true))
    window.localStorage.setItem("advanceInProgress", advanceID)
  }

  return (
    <GenericContainer>
      {Object.keys(newAdvance).length >= 1 && (
        <>
          <AdvanceStepper stepNumber={4}></AdvanceStepper>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            New Advance Confirmation
          </Typography>
          {/* <Typography variant="h5">Advance ID: {advanceID}</Typography> */}
          {/* <Box sx={styles.summaryContainer}>
            <Typography variant="h6">Property Address Details</Typography>
            <Typography>Reason for advance: {reason}</Typography>

            {description && (
              <Typography variant="body1">
                Reason description: {description}
              </Typography>
            )}
            <Typography>
              Property address: {first_line_address},
              {second_line_address && ` ${second_line_address},`}
              {` ${postcode}`}, {` ${town_or_city}`}, {` ${country}`}
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate(`/new-advance-1/${advanceID}/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon fontSize="small"></EditIcon>
                <Typography variant="h6">Edit these details</Typography>
              </Box>
            </Button>
          </Box> */}

          <Box sx={styles.summaryContainer}>
            <Typography variant="h6">Documents</Typography>
            <Typography>Tenancy agreement(s): </Typography>

            {rentAgreementFiles.length > 0 &&
              rentAgreementFiles.map((file) => {
                const { id, file_url, filename } = file
                return (
                  <Typography key={id}>
                    <a id={id} target="_blank" rel="noreferrer" href={file_url}>
                      {filename}
                    </a>
                  </Typography>
                )
              })}
            <Typography>Credit check document(s):</Typography>
            {rentProtectionFiles.length > 0 &&
              rentProtectionFiles.map((file) => {
                const { id, file_url, filename } = file
                return (
                  <Typography key={id}>
                    <a id={id} target="_blank" rel="noreferrer" href={file_url}>
                      {filename}
                    </a>
                  </Typography>
                )
              })}
            <Button
              variant="contained"
              onClick={() => navigate(`/new-advance-1/${advanceID}/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon fontSize="small"></EditIcon>
                <Typography variant="h6">Edit these details</Typography>
              </Box>
            </Button>
          </Box>

          <Box sx={styles.summaryContainer}>
            <Typography variant="h6">Financials</Typography>
            <Typography>
              Monthly rent for this property: £
              {parseFloat(monthly_rent).toLocaleString()}
            </Typography>
            <Typography>
              Total (non-rental) income from other sources (pre-tax): £
              {parseFloat(total_income_from_other_sources).toLocaleString()}
            </Typography>
            <Typography>
              Mortgaged property(ies): {mortgaged_property ? "Yes" : "No"}
            </Typography>
            {mortgaged_property && (
              <>
                <Typography>
                  Monthly mortgage amount: £
                  {parseFloat(monthly_mortgage_amount).toLocaleString()}
                </Typography>
                {/* {mortgage_fixed_or_variable === "Fixed" && (
                  <Typography>
                    Fixed mortgage expirty date:{" "}
                    {dayjs(date_of_fixed_mortgage_expiry).format("DD/MM/YYYY")}
                  </Typography>
                )} */}
              </>
            )}

            <Typography variant="h6">Your Bank Details</Typography>
            <Typography>
              Name on bank account: {name_on_bank_account}
            </Typography>
            <Typography>
              Your bank account number: {bank_account_number}
            </Typography>
            <Typography>
              Your bank account sort code: {sort_code_bank_account}
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate(`/new-advance-2/${advanceID}/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon fontSize="small"></EditIcon>
                <Typography variant="h6">Edit these details</Typography>
              </Box>
            </Button>
          </Box>

          <Box sx={styles.summaryContainer}>
            <Typography variant="h6">Advance Amounts</Typography>
            <Typography>Reason for Advance: {reason}</Typography>
            <Typography>
              Amount of rent you are selling: £
              {parseFloat(loan_amount).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
            <Typography>
              Advance repayment duration: {loan_term} months
            </Typography>
            <Typography>
              Monthly payments to cover this Advance: £
              {parseFloat(estimated_loan_monthly_payment).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                },
              )}
            </Typography>
            <Typography>
              Total repayable for this Advance: £
              {formatFiatNumberTwoDecimals(
                estimated_loan_monthly_payment * loan_term,
              )}
            </Typography>
            <Typography>
              Total fees: £
              {formatFiatNumberTwoDecimals(
                estimated_loan_monthly_payment * loan_term - loan_amount,
              )}
            </Typography>
            <Typography>
              Monthly Factored fee: {advance_monthly_factored_fee}%
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate(`/new-advance-3/${advanceID}/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon fontSize="small"></EditIcon>
                <Typography variant="h6">Edit these details</Typography>
              </Box>
            </Button>
          </Box>
          {/* <Box sx={styles.summaryContainer}>
            <Typography variant="h6">Your Bank Details</Typography>
            <Typography>
              Name on bank account: {name_on_bank_account}
            </Typography>
            <Typography>
              Your bank account number: {bank_account_number}
            </Typography>
            <Typography>
              Your bank account sort code: {sort_code_bank_account}
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate(`/new-advance-4/${advanceID}/`)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <EditIcon fontSize="small"></EditIcon>
                <Typography variant="h6">Edit these details</Typography>
              </Box>
            </Button>
          </Box> */}
          <Box>
            <Checkbox
              checked={acceptTerms}
              onChange={handleTickbox}
              name="acceptTermsConditions"
              sx={{
                margin: "0px",
                padding: "0px",
                "& .MuiSvgIcon-root": { fontSize: 24 },
              }}
            />
            <label>
              I accept Factored's{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.factored.co/legal"
              >
                Terms & Conditions
              </a>{" "}
              for this Advance
            </label>
          </Box>
          {errorText ? <Alert severity="error">{errorText}</Alert> : ""}
          {/* {checkIfUserIsVerified() ? (
            <Button sx={{ p: 1.5 }} variant="contained" onClick={handleSubmit}>
              <Typography variant="h6">Submit New Advance</Typography>
            </Button>
          ) : (
            <Button
              sx={{ p: 1.5 }}
              variant="contained"
              // component={Link}
              // to={"/verification-info"}
              onClick={openPopup}
            >
              <Typography variant="h6">Verify Your Identity</Typography>
            </Button>
          )} */}
          <Button sx={{ p: 1.5 }} variant="contained" onClick={handleSubmit}>
            <Typography variant="h6">Submit New Advance</Typography>
          </Button>
          {/* <ConfAdvancePopup></ConfAdvancePopup> */}
          <ExperimentPopup></ExperimentPopup>
        </>
      )}
    </GenericContainer>
  )
}

export default NewAdvanceConf
