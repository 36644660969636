import { Button, Typography, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import { useState, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"

const ActivateAccount = () => {
  document.title = "Factored - Activate Account"
  const [buttonText, setButtonText] = useState("Send Email")
  const [timeLeft, setTimeLeft] = useState(null)
  const [displayAlert, setDisplayAlert] = useState(false)
  const [ifInitialRender, setIfInitialRender] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
    }
  }, [])

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null)
      setButtonText("Send Email")
      setDisplayAlert(false)
    }
    // exit early when we reach 0
    if (!timeLeft) return

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  const API_URL = "api/user/request-activate-account-email/"

  const makeAPIRequest = async () => {
    try {
      const response = await axios.patch(API_URL, {})
      // console.log(response)
    } catch (error) {
      // console.log(error)
      console.log(error)
    }
  }

  const handleButtonClick = () => {
    // set this to 60 seconds once in prod
    setTimeLeft(60)
    setButtonText("")
    setDisplayAlert(true)
    makeAPIRequest()
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Please activate your account
      </Typography>
      <Typography variant="b1">
        Before you can start using Factored, please verify the email address
        linked to your account by clicking on the email we will send you.
      </Typography>

      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handleButtonClick}
        disabled={buttonText === "Send Email" ? false : true}
      >
        <Typography variant="h6">
          {buttonText} {timeLeft}
        </Typography>
      </Button>
      {displayAlert && (
        <Alert>
          We have sent you a new email with a special link to activate your
          account
        </Alert>
      )}
    </GenericContainer>
  )
}
export default ActivateAccount
