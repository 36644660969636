// For better UX, the advance creation process is broken down into 4 steps
// These 4 steps are 4 different pages linked in a logical chain

// Stage 1 requires the user to provide basic details of the new advance
// These details include property address, reason for advance and rent amount

import React from "react"
import { useState, useEffect } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import axios from "axios"
import AddFilesImage from "../../assets/add_files.svg"

import { Box, Button, Typography } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import AdvanceStepper from "../../components/AdvanceStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import GenericContainer from "../../ui/GenericContainer"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const NewAdvancePreStage1 = () => {
  const styles = {
    checkBoxContainer: {
      display: "flex",
      alignItems: "center",
    },
    iconMargin: {
      marginRight: "0.2rem",
    },
  }

  // We fetch the advance ID from the URL using the below hook
  let { advanceID } = useParams()

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  // Initial useEffect to check for the local auth token
  // and fetching of a single advance
  useEffect(() => {
    document.title = `Factored - Advance ${advanceID}`
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getSingleAdvance()
    }
  }, [])

  // This fetches the advance using the ID from the useParams hook
  const getSingleAdvance = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/advances/${advanceID}/`)
      // console.log(data)
      setNewAdvance(data)
      setIsLoading(false)
    } catch (error) {
      // console.log(error)
      setErrorText(error)
    }
  }

  const [errorText, setErrorText] = useState("")
  const [newAdvance, setNewAdvance] = useState({})

  // If the page is loading, we display this placeholder,
  // and wait until the advance details render
  // if (isLoading) {
  //   return <PleaseWait></PleaseWait>
  // }

  return (
    <GenericContainer>
      {Object.keys(newAdvance).length >= 1 && (
        <>
          <AdvanceStepper stepNumber={0}></AdvanceStepper>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Before you begin...
          </Typography>
          <img
            src={AddFilesImage}
            style={{ width: "7rem", margin: "0 auto" }}
          />
          <Typography variant="b1">
            To create an Advance, please have the following information ready:
          </Typography>
          <Box style={styles.checkBoxContainer}>
            <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>
            Property monthly rent and your financial details
          </Box>
          <Box style={styles.checkBoxContainer}>
            <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>Copy
            of your signed tenancy agreement
          </Box>
          <Box style={styles.checkBoxContainer}>
            <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>Bank
            details (account number and sort code)
          </Box>
          <Box style={styles.checkBoxContainer}>
            <DoneIcon fontSize="small" style={styles.iconMargin}></DoneIcon>
            Up-to-date credit report (preferably&nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.checkmyfile.com/checkout.htm"
            >
              checkmyfile
            </a>
            )
          </Box>
          <Button
            component={Link}
            to={`/new-advance-1/${advanceID}/`}
            sx={{ p: 1.5 }}
            variant="contained"
            // onClick={handleSubmit}
            // disabled={!!errorText}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Next Step</Typography>
              <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
            </Box>
          </Button>
        </>
      )}
    </GenericContainer>
  )
}

export default NewAdvancePreStage1
