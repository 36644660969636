// This page contains the details of the user
// All fields, except for the contact number, are read only
// This was set by the customer requirements

import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Box, Button, Typography } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const ProfilePage = () => {
  const styles = {
    boxWithFlex: {
      display: "flex",
      gap: "0.5rem",
    },
  }

  const navigate = useNavigate()

  // Initial useEffect to check for the local auth token
  // and fetching of user data
  useEffect(() => {
    document.title = "Factored - Your Profile"
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
    }
  }, [])

  // This fetches the user details
  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // console.log("API response", response.data)
      setUserDetails(response.data)
    } catch (error) {
      console.log("context error", error)
    }
  }

  const [userDetails, setUserDetails] = useState({})

  // This breaks down the fields belonging to userDetails
  // so that it would be easier to use them in JSX
  const {
    title,
    email,
    first_name,
    last_name,
    mobile_number,
    is_identity_verified,
    is_address_verified,
  } = userDetails

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Your Profile
      </Typography>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Title:</Typography>
        <Typography>{title}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>First name:</Typography>
        <Typography>{first_name}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Last name:</Typography>
        <Typography>{last_name}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Email addres:</Typography>
        <Typography>{email}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Identity Verification status:</Typography>
        <Typography>
          {is_identity_verified ? " Verified" : " Unverified"}
        </Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>
          Current Address Verification status:
        </Typography>
        <Typography>
          {is_address_verified ? " Verified" : " Unverified"}
        </Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Contact number:</Typography>
        <Typography> {mobile_number}</Typography>
      </Box>
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={() => navigate("/dashboard")}
      >
        <Typography variant="h6">Back To Dashboard</Typography>
      </Button>
    </GenericContainer>
  )
}

export default ProfilePage
