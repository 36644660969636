import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  idOfTheAdvance: "",
}

const advanceKycSlice = createSlice({
  name: "advanceKycFlow",
  initialState,
  reducers: {
    setInitialAdvance: (state, action) => {
      state.idOfTheAdvance = action.payload
    },
  },
})

export const { setInitialAdvance } = advanceKycSlice.actions

export default advanceKycSlice.reducer
