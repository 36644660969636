// For better UX, the advance creation process is broken down into 4 steps
// These 4 steps are 4 different pages linked in a logical chain

// Stage 4 allows the user to enter their bank details
// These bank details would be used for the loan payout at the start

import React from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import {
  Box,
  Button,
  Typography,
  TextField,
  Alert,
  Checkbox,
} from "@mui/material"
import AdvanceStepper from "../../components/AdvanceStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import axios from "axios"
import GenericContainer from "../../ui/GenericContainer"
import dayjs from "dayjs"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const NewAdvance2 = () => {
  // We fetch the advance ID from the URL using the below hook
  let { advanceID } = useParams()

  const [errorText, setErrorText] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [displayDatePicker, setDisplayDatePicker] = useState(false)
  const [monthlyRentError, setMonthlyRentError] = useState("")
  const [selectedButton, setSelectedButton] = useState("yes")
  const [fixedMortgageButton, setFixedMortgageButton] = useState("no")

  const navigate = useNavigate()

  // Initial useEffect to check for the local auth token
  // and fetching of a single advance
  useEffect(() => {
    document.title = `Factored - Advance ${advanceID}`
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getSingleAdvance()
    }
  }, [])

  // This fetches the advance using the ID from the useParams hook
  const getSingleAdvance = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`api/advances/${advanceID}/`)
      // console.log(data)
      setNewAdvance(data)
      setIsLoading(false)

      data.mortgaged_property
        ? setSelectedButton("yes")
        : setSelectedButton("no")

      data.do_any_fixed_term_mortgages_expire_within_12_months
        ? setFixedMortgageButton("yes")
        : setFixedMortgageButton("no")
    } catch (error) {
      console.log(error)
    }
  }

  const [newAdvance, setNewAdvance] = useState({})
  const [checkedCheckbox, setCheckedCheckbox] = useState(false)
  const [checkBoxError, setCheckBoxError] = useState("")

  // This breaks down the some of the existing fields of an advance
  const {
    mortgaged_property,
    total_income_from_other_sources,
    monthly_mortgage_amount,
    monthly_rent,
    name_on_bank_account,
    bank_account_number,
    sort_code_bank_account,
    do_any_fixed_term_mortgages_expire_within_12_months,
  } = newAdvance

  // This sends a PATCH request to the backend with new advance details
  // And then navigates the user to the next stage
  const handleSubmit = (e) => {
    e.preventDefault()

    if (checkedCheckbox === false) {
      // setErrorText("Please confirm the above condition before proceeding.")
      setCheckBoxError("Please confirm the above condition before proceeding.")
      return
    }
    setCheckBoxError("")
    const updateExistingAdvance = async () => {
      try {
        const response = await axios.patch(`api/advances/${advanceID}/`, {
          name_on_bank_account,
          bank_account_number,
          sort_code_bank_account,
          mortgaged_property,
          total_income_from_other_sources,
          monthly_mortgage_amount,
          monthly_rent,
          do_any_fixed_term_mortgages_expire_within_12_months,
        })
        // console.log("API response", response.data)

        setNewAdvance(response.data)
        setMonthlyRentError("")
        navigate(`/new-advance-3/${advanceID}/`)
      } catch (error) {
        // setErrorText(error.response.data.detail)
        console.log(error)
      }
    }

    if (monthly_rent === "0" || monthly_rent === "0.00") {
      setMonthlyRentError("Please enter your monthly rent for this property")
      return
    }
    updateExistingAdvance()
  }

  // This updates newAdvance and the form whenever there is a change detected
  const handleChange = (e) => {
    if (
      e.target.name === "monthly_rent" ||
      e.target.name === "total_income_from_other_sources" ||
      e.target.name === "monthly_mortgage_amount"
    ) {
      e.target.value = e.target.value.replace(/[^0-9.-]/g, "")
      e.target.value = Number(e.target.value.replace(/\,/g, ""))
      e.target.value = Number(e.target.value.replace(/\./g, ""))
    }

    setNewAdvance((prevVal) => {
      return { ...prevVal, [e.target.name]: e.target.value }
    })
  }

  // If the page is loading, we display this placeholder,
  // and wait until the advance details render
  if (isLoading) {
    return (
      <div className="generic-container">
        <h2>Please wait...</h2>
      </div>
    )
  }

  return (
    <GenericContainer>
      {Object.keys(newAdvance).length >= 1 && (
        <>
          <AdvanceStepper stepNumber={1}></AdvanceStepper>
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Financials
          </Typography>

          <Typography variant="h6">
            Connect the bank account where you received your rental income.
          </Typography>
          <Typography>
            We'll need you to connect your main bank account where you receive
            your rent and pay out your mortgage (if you have one).{" "}
            <span className="italic">
              Opt for Open Banking to boost your advance approval chances.
              Alternatively, email your 3 latest bank statements to
              <a href="mailto: support@factored.co"> support@factored.co</a> if
              you prefer
            </span>
          </Typography>

          <a
            // style={{ margin: "0 auto" }}
            target="_blank"
            rel="noreferrer"
            href="https://planky.com/share/factored-technologies-limited"
          >
            <Button variant="contained">Connect Bank Account</Button>
          </a>

          <Typography variant="h6">
            Do you have a mortgage associated with the property(ies)?
          </Typography>
          <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
            <Button
              sx={{ width: "100%" }}
              variant={selectedButton === "yes" ? "contained" : "outlined"}
              onClick={() => {
                setNewAdvance({ ...newAdvance, mortgaged_property: "True" })
                setSelectedButton("yes")
              }}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setNewAdvance({ ...newAdvance, mortgaged_property: "False" })
                setSelectedButton("no")
              }}
              sx={{ width: "100%" }}
              variant={selectedButton === "no" ? "contained" : "outlined"}
            >
              No
            </Button>
          </Box>
          {selectedButton === "yes" && (
            <>
              <TextField
                value={`£${parseFloat(
                  monthly_mortgage_amount,
                ).toLocaleString()}`}
                name="monthly_mortgage_amount"
                onChange={handleChange}
                label="Monthly mortgage amount"
              ></TextField>
              <Typography>
                Do any fixed term mortgages (if applicable) on the property(ies)
                expire in the next 12 months?
              </Typography>
              <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
                <Button
                  sx={{ width: "100%" }}
                  variant={
                    fixedMortgageButton === "yes" ? "contained" : "outlined"
                  }
                  onClick={() => {
                    setDisplayDatePicker("Fixed")
                    setNewAdvance({
                      ...newAdvance,
                      do_any_fixed_term_mortgages_expire_within_12_months: true,
                    })
                    setFixedMortgageButton("yes")
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    setNewAdvance({
                      ...newAdvance,
                      do_any_fixed_term_mortgages_expire_within_12_months: false,
                    })
                    setFixedMortgageButton("no")
                  }}
                  sx={{ width: "100%" }}
                  variant={
                    fixedMortgageButton === "no" ? "contained" : "outlined"
                  }
                >
                  No
                </Button>
              </Box>
              {/* <Typography>
                Please select when your fixed mortgage expires
              </Typography> */}

              {/* {displayDatePicker === "Fixed" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={
                        date_of_fixed_mortgage_expiry === "0"
                          ? dayjs()
                          : dayjs(date_of_fixed_mortgage_expiry)
                      }
                      onChange={(newValue) => {
                        // setDate(newValue)
                        console.log(newValue.toISOString())

                        setNewAdvance({
                          ...newAdvance,
                          date_of_fixed_mortgage_expiry: newValue.toISOString(),
                        })
                      }}
                      label="Please select when your fixed mortgage expires"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )} */}
            </>
          )}
          <TextField
            id="outlined-basic"
            label="Monthly (combined) rent for the property(ies)"
            variant="outlined"
            value={`£${parseFloat(monthly_rent).toLocaleString()}`}
            onChange={handleChange}
            required
            type="text"
            name="monthly_rent"
          />
          <TextField
            value={`£${parseFloat(
              total_income_from_other_sources,
            ).toLocaleString()}`}
            name="total_income_from_other_sources"
            onChange={handleChange}
            label="Total non-rental income from all other sources (pre-tax)"
          ></TextField>

          {monthlyRentError && (
            <Alert severity="error">{monthlyRentError}</Alert>
          )}

          <Typography variant="h6">
            Please share the preferred bank details to receive the advance
          </Typography>
          <TextField
            id="outlined-basic"
            label="Name on your bank account"
            variant="outlined"
            value={name_on_bank_account}
            onChange={handleChange}
            required
            type="text"
            name="name_on_bank_account"
          />
          {errorText?.name_on_bank_account && (
            <Alert severity="error">{errorText?.name_on_bank_account}</Alert>
          )}
          <TextField
            id="outlined-basic"
            label="Bank account number"
            variant="outlined"
            value={bank_account_number}
            onChange={handleChange}
            required
            type="text"
            name="bank_account_number"
          />
          {errorText?.bank_account_number && (
            <Alert severity="error">{errorText?.bank_account_number}</Alert>
          )}
          <TextField
            id="outlined-basic"
            label="Sort code"
            variant="outlined"
            value={sort_code_bank_account}
            onChange={handleChange}
            required
            type="text"
            name="sort_code_bank_account"
          />
          {errorText?.sort_code_bank_account && (
            <Alert severity="error">{errorText?.sort_code_bank_account}</Alert>
          )}

          <Box>
            <Checkbox
              checked={checkedCheckbox}
              onChange={() => setCheckedCheckbox(!checkedCheckbox)}
              name="acceptTermsConditions"
              sx={{
                margin: "0px",
                padding: "0px",
                "& .MuiSvgIcon-root": { fontSize: 24 },
              }}
            />
            <label>
              I confirm that I've signed up to Open Banking or sent bank
              statements.
            </label>
          </Box>
          {checkBoxError && <Alert severity="error">{checkBoxError}</Alert>}
          {errorText && <Alert severity="error">{errorText}</Alert>}
          <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
            <Button
              component={Link}
              to={`/new-advance-1/${advanceID}/`}
              sx={{ p: 1.5, width: "50%" }}
              variant="outlined"
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
                <Typography variant="h6">Previous Step</Typography>
              </Box>
            </Button>
            <Button
              component={Link}
              to={`/new-advance-3/${advanceID}/`}
              sx={{ p: 1.5, width: "50%" }}
              variant="contained"
              onClick={handleSubmit}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Next Step</Typography>
                <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
              </Box>
            </Button>
          </Box>
        </>
      )}
    </GenericContainer>
  )
}

export default NewAdvance2
