// This page is the dashboard of a logged in user
// It contains the 4 checks needed to create an advance
// It also includes a table of all of the advances
// These advances could be in different states

import React, { useEffect, useState } from "react"

import { Link, useNavigate } from "react-router-dom"
import axios from "axios"
import { Box, Button, Typography } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import DempStepper from "../components/DemoStepper"
import DemoTableTwo from "../components/DataTable copy"
import DashboardImage from "../assets/dashboard_table.svg"
import { displayButton } from "../redux/headerSlice"
import { useDispatch } from "react-redux"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const Dashboard = () => {
  const [stepperNumber, setStepperNumber] = useState(0)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // This render state triggers the frontend to pull a new list of
  // advances whenever a user deletes one in the dashboard
  const [render, setRender] = useState(0)

  const [userDetails, setUserDetails] = useState({})

  const [advanceList, setAdvanceList] = useState({})

  // This useEffect fetches the local auth token
  // If there is no local auth token, then the user is taken to the homepage
  // If the token exists, then we fetch the user profile and advance details
  useEffect(() => {
    document.title = "Factored - Dashboard"
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
      getUserAdvanceList()
    }
  }, [render])

  // This pulls the user information from the backend
  // Information about what checks have been completed are passed
  // into the dashboard
  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // console.log("API response", response.data)
      setUserDetails(response.data)
      if (response.data.is_identity_verified) {
        setStepperNumber(1)
      }

      if (response.data.is_address_verified) {
        setStepperNumber(2)
      }
      if (response.data.is_mobile_number_verified) {
        setStepperNumber(3)
        dispatch(displayButton(false))
      }
    } catch (error) {
      console.log("context error", error)
    }
  }

  // This fetches the list of all advances belonging to the user
  const getUserAdvanceList = async () => {
    try {
      const response = await axios.get("api/advances/all/")
      // console.log(response.data)
      setAdvanceList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  // This creates a new advance and navigates the user to that advance
  const createNewAdvance = async () => {
    try {
      const response = await axios.post("api/advances/create/")
      // console.log(newAdvanceId)
      // console.log("API response", response.data)
      // setNewAdvanceId(response.data.id)
      navigate(`/new-advance-info/${response.data.id}/`)
    } catch (error) {
      console.log("context error", error)
    }
  }

  const [snackbarText, setSnackbarText] = useState("")

  // This deletes an advance in the table and triggers
  // the useEffect to fetch new list of advances
  const deleteNewAdvance = async (id) => {
    // console.log(id)
    try {
      const response = await axios.delete(`api/advances/${id}/`)
      // console.log("API response", response.data)
      setRender(render + 1)
      setSnackbarText(`Advance ${id} deleted`)
    } catch (error) {
      console.log("context error", error)
    }
  }

  const [boxHeight, setBoxHeight] = useState("50px")

  const handleBoxChange = () => {
    if (boxHeight === "50px") setBoxHeight("400px")
    else if (boxHeight === "400px") setBoxHeight("50px")
  }

  return (
    <GenericContainer dashboard={true}>
      <h2>Your dashboard</h2>
      {/* <Typography variant="h2" sx={{ textAlign: "center" }}>
        Verification Status
      </Typography>
      <DempStepper stepNumber={stepperNumber}></DempStepper> */}
      {/* {Object.keys(userDetails).length >= 0 && !userDetails.mobile_number && (
          <>
            <h4>Complete these steps to create your first advance</h4>
            <Link to="/verification-info">
              <Button sx={{ p: 1.5 }} variant="contained">
                <Typography variant="h6">Complete Checks</Typography>
              </Button>
            </Link>
          </>
        )} */}
      {/* <Box
          sx={{
            width: "100%",
            border: "1px solid black",
            height: boxHeight,
            textAlign: "right",
          }}
        >
          <Typography onClick={handleBoxChange}>close/open</Typography>
        </Box> */}
      <div className="create-advance-button">
        {/* This block allows the user to create a new advance
        
        For demo purposes, the checks above are not enforced
        i.e. you can still create an advance without doing all 4 checks

        However, the above logic can be simply turned on below

         */}
        <Link onClick={createNewAdvance}>
          {/* <button className="btn-primary" disabled={KYC && addressHistoryStatus
              && phoneNumber ? false : true}>Create Advance</button> */}
          <Button sx={{ p: 1.5 }} variant="contained">
            <Typography variant="h6">Create Advance</Typography>
          </Button>
        </Link>
      </div>

      <div>
        <h2>Your Advances</h2>
        {/* {advanceList.length > 1 && (
          <DemoTable
            advanceList={advanceList}
            deleteNewAdvance={deleteNewAdvance}
            snackbarText={snackbarText}
          ></DemoTable>
        )} */}
        {advanceList.length >= 1 && (
          <DemoTableTwo
            advanceList={advanceList}
            deleteNewAdvance={deleteNewAdvance}
            snackbarText={snackbarText}
          ></DemoTableTwo>
        )}
        {advanceList.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5rem",
            }}
          >
            <Box sx={{ height: "10rem" }}>
              <img
                src={DashboardImage}
                style={{ height: "100%", width: "100%" }}
                alt="not found"
              />
            </Box>

            <Typography variant="h3">
              You do not currently have any Advances
            </Typography>
          </Box>
        )}
      </div>
    </GenericContainer>
  )
}

export default Dashboard
