// This page is for user going through Know Your Customer (KYC) check
// This is made possible by Persona (https://withpersona.com/)
// When the user is successfully verified, then Persona sends
// a request to the backend informing of this event

import React, { useEffect, useState } from "react"
import Persona from "persona"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Box, Button, Typography, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import DemoStepper from "../components/DemoStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { Link } from "react-router-dom"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const IDVerification = () => {
  const navigate = useNavigate()

  const [buttonIsDisabled, setButtonIsDisabled] = useState(false)

  const [userDetails, setUserDetails] = useState({})

  // Initial useEffect to check for the local auth token
  // and fetching of user data
  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    document.title = "Factored - Identity Verification"
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
    }
  }, [])

  // This fetches the user data
  // If the user is already verified, we navigate them to the dashboard
  // If the user is not verified, then they can stay on this page
  // and start the KYC verification process
  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // console.log("API response", response.data)
      setUserDetails(response.data)
      console.log(response.data)
      // if (response.data.is_identity_verified) {
      //   navigate("../dashboard")
      // }
    } catch (error) {
      console.log("context error", error)
    }
  }

  // setInterval(() => {
  //   if (!buttonIsDisabled) {
  //     getUserProfile()
  //   }

  //   return () => clearInterval(interval)
  // }, 10000)

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (!buttonIsDisabled) {
        getUserProfile()
      }
    }, 5000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [])

  const startVerification = () => {
    // console.log("start verification")
    setButtonIsDisabled(true)
    const client = new Persona.Client({
      templateId: `${process.env.REACT_APP_PERSONA_TEMPLATE_ID}`,
      environment: `${process.env.REACT_APP_PERSONA_ENV_ID}`,
      // templateId: "itmpl_GcTWBXkCDTy4oH1YtiW5Agnk",
      // environment: "sandbox",
      referenceId: userDetails.id,
      onReady: () => client.open(),
      onComplete: ({ inquiryId, status, fields }) => {
        // setUserDetails({ ...userDetails, is_identity_verified: true })
        setButtonIsDisabled(false)
        getUserProfile()
      },
      onCancel: ({ inquiryId, sessionToken }) =>
        console.log(inquiryId, sessionToken, "onCancel"),
      onError: (error) => console.log(error),
    })
  }

  return (
    <GenericContainer>
      <DemoStepper stepNumber={0}></DemoStepper>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Identity Verification
      </Typography>
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        disabled={userDetails.is_identity_verified}
        onClick={startVerification}
      >
        <Typography variant="h6">Get verified</Typography>
      </Button>
      {buttonIsDisabled && (
        <Typography sx={{ textAlign: "center" }} variant="p">
          Loading... Please wait
        </Typography>
      )}
      {userDetails.is_identity_verified ? (
        <Alert severity="success">
          We have sucessfully verified your identity and you can move to the
          next step
        </Alert>
      ) : (
        ""
      )}

      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to="/verification-info"
          sx={{ p: 1.5, width: "50%" }}
          variant="outlined"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
            <Typography variant="h6">Previous Step</Typography>
          </Box>
        </Button>
        <Button
          component={Link}
          to="/current-address-verification"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          disabled={!userDetails.is_identity_verified}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Next Step</Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default IDVerification
