import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"

const steps = [
  "Identity Verification",
  "Current Address Verification",
  "Contact Number",
]

export default function HorizontalLabelPositionBelowStepper({ stepNumber }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={stepNumber} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
