// This page contains the address history verification
// We require the users to submit at least 3 years of past
// addresses that they lived at
// This will contribute to a soft credit check

import React from "react"
import AddressHistoryInput from "../components/AddressHistoryInput"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import DemoStepper from "../components/DemoStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { Link } from "react-router-dom"
import axios from "axios"

const AddressVerification = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      getUserProfile()
    }
  }, [])

  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // if (response.data.has_address_history) {
      //   navigate("../dashboard")
      // }
    } catch (error) {
      console.log("context error", error)
    }
  }

  return (
    <GenericContainer>
      {" "}
      <DemoStepper stepNumber={2}></DemoStepper>
      <Typography sx={{ textAlign: "center" }} variant="h2">
        Address Verification
      </Typography>
      <AddressHistoryInput></AddressHistoryInput>
      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to="/current-address-verification"
          sx={{ p: 1.5, width: "50%" }}
          variant="outlined"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
            <Typography variant="h6">Previous Step</Typography>
          </Box>
        </Button>
        <Button
          component={Link}
          to="/add-contact-number"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          // disabled={!userDetails.is_address_verified}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Next Step</Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default AddressVerification
