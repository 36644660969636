import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Typography, Button, Box, Tooltip, Alert } from "@mui/material"
import { useNavigate } from "react-router-dom"

import Snackbar from "@mui/material/Snackbar"
import Slide from "@mui/material/Slide"

import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

const tableHeadings = [
  "Action",
  "Advance ID",
  "Status",
  // "Property Postcode",
  "Total Advance Amount (£)",
  "Total Term (m)",
  "Remaining Advance Balance (£)",
  "Monthly Payment Amount (£)",
]

const slideTransition = (props) => {
  return <Slide {...props} direction="up" />
}

export default function BasicTable({
  advanceList,
  deleteNewAdvance,
  snackbarText,
}) {
  const styles = {
    tableHead: (theme) => ({
      background: "#4fd1a6",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    }),
    printerRowLarge: (theme) => ({
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    }),
    printerRow: (theme) => ({
      display: "none",
      [theme.breakpoints.down("lg")]: {
        display: "flex",
        flexDirection: "column",
      },
    }),
  }

  const navigate = useNavigate()
  const [open, setOpen] = useState(false)

  const deleteAndShowSnackBar = (id) => {
    deleteNewAdvance(id)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <TableContainer component={Paper} elevation={5}>
        <Snackbar
          open={open}
          onClose={handleClose}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          TransitionComponent={slideTransition}
        >
          <Alert severity="success">{snackbarText}</Alert>
        </Snackbar>
        <Table aria-label="simple table">
          <TableHead sx={styles.tableHead}>
            <TableRow>
              {tableHeadings.map((heading) => {
                return (
                  <TableCell key={heading} align="center">
                    <Typography variant="h6">{heading}</Typography>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {advanceList.map((advance, index) => {
              const {
                id,
                status,
                postcode,
                estimated_loan_monthly_payment,
                loan_amount,
                loan_term,
                
              } = advance
              return (
                <React.Fragment key={id}>
                  <TableRow sx={styles.printerRowLarge}>
                    <TableCell>
                      {" "}
                      <Box
                        sx={{
                          display: "flex",

                          gap: "0.5rem",
                        }}
                      >
                        {status === "Incomplete" && (
                          <>
                            <Tooltip title="Edit Advance">
                              <Button
                                onClick={() => navigate(`/new-advance-1/${id}`)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <EditIcon></EditIcon>
                              </Button>
                            </Tooltip>

                            <Tooltip title="Delete Advance">
                              <Button
                                onClick={() => deleteAndShowSnackBar(id)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <DeleteForeverIcon></DeleteForeverIcon>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        {status !== "Incomplete" && (
                          <Box sx={{ margin: "0 auto" }}>
                            <Tooltip title="View Advance">
                              <Button
                                onClick={() => navigate(`/advances/${id}`)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <RemoveRedEyeIcon></RemoveRedEyeIcon>
                              </Button>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {id}
                    </TableCell>

                    <TableCell align="center">{status}</TableCell>

                    {/* <TableCell align="center">{postcode}</TableCell> */}
                    <TableCell align="center">
                      £{parseFloat(loan_amount).toLocaleString()}
                    </TableCell>
                    <TableCell align="center">{loan_term}</TableCell>
                    <TableCell align="center">0</TableCell>
                    <TableCell align="center">
                      £
                      {parseFloat(
                        estimated_loan_monthly_payment,
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                  {/* Mobile view  */}
                  <TableRow sx={styles.printerRow}>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="h6"
                        sx={{ textAlign: "center", p: "0.5rem" }}
                      >
                        Advance {id}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <Typography>{status}</Typography>
                        {status === "Incomplete" && (
                          <>
                            <Tooltip title="Edit Advance">
                              <Button
                                onClick={() => navigate(`/new-advance-1/${id}`)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <EditIcon></EditIcon>
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete Advance">
                              <Button
                                onClick={() => deleteAndShowSnackBar(id)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <DeleteForeverIcon></DeleteForeverIcon>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                        {status !== "Incomplete" && (
                          <>
                            <Tooltip title="View Advance">
                              <Button
                                onClick={() => navigate(`/advances/${id}`)}
                                variant="contained"
                                style={styles.smallerButton}
                              >
                                <RemoveRedEyeIcon></RemoveRedEyeIcon>
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Table>
                        <TableHead>
                          {/* <TableRow>
                            <TableCell>
                              <Typography>Property postcode:</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{postcode}</Typography>
                            </TableCell>F
                          </TableRow> */}
                          <TableRow>
                            <TableCell>
                              <Typography>Total Advance Amount (£):</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                £{parseFloat(loan_amount).toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>Total term (m):</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{loan_term}</Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                Remaining Advance Balance (£):
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                £{parseFloat(loan_amount).toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Typography>
                                Monthly Payment Amount (£):
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>
                                £
                                {parseFloat(
                                  estimated_loan_monthly_payment,
                                ).toLocaleString()}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
