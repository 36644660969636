import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Box, Button, Typography, TextField, Alert } from "@mui/material"
import GenericContainer from "../ui/GenericContainer"
import DemoStepper from "../components/DemoStepper"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { Link } from "react-router-dom"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import UndoIcon from "@mui/icons-material/Undo"
import Zoom from "@mui/material/Zoom"
import Grow from "@mui/material/Grow"
axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const AddContactNumber = () => {
  const navigate = useNavigate()

  const [buttonIsDisabled, setButtonIsDisabled] = useState(false)
  const [showSecondStage, setShowSecondStage] = useState(false)
  const [userDetails, setUserDetails] = useState({})

  const [errorMessage, setErrorMessage] = useState("")
  const [infoMessage, setInfoMessage] = useState("")
  const [renderNewComponent, setRenderNewComponent] = useState(false)

  // Initial useEffect to check for the local auth token
  // and fetching of user data
  useEffect(() => {
    const token = window.localStorage.getItem("factored_token")
    document.title = "Factored - Contact Number"
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getUserProfile()
    }
  }, [renderNewComponent])

  // This fetches the user data
  // If the user is already current address verified,
  // we navigate them to the dashboard
  // If the user is not current address verified,
  // then they can stay on this page
  // and start the proof of address verification process
  const getUserProfile = async () => {
    try {
      const response = await axios.get("api/user/me/")
      // console.log("API response", response.data)
      setUserDetails(response.data)
      // if (response.data.is_address_verified) {
      //   navigate("../dashboard")
      // }
    } catch (error) {
      console.log("context error", error)
    }
  }
  const [newNumber, setNewNumber] = useState()
  const [SMSToken, setSMSToken] = useState("")

  const submitFirstStage = async (e) => {
    e.preventDefault()
    if (!newNumber) {
      setErrorMessage("Please enter a valid contact number")
    } else {
      try {
        await axios.patch("api/user/request-sms-verification/", {
          phone_number: newNumber,
        })
        setShowSecondStage(true)
        setErrorMessage("")
        setInfoMessage(
          `We have sent a SMS message to ${newNumber} with a special 6 digit code.`,
        )
      } catch (error) {
        console.log(error)
        setErrorMessage(error.response.data.message)
      }
    }
  }

  const submitSecondStage = async (e) => {
    e.preventDefault()
    try {
      await axios.patch("api/user/verify-sms-token/", {
        sms_token: SMSToken,
      })
      setRenderNewComponent(true)
    } catch (error) {
      console.log(error)
      setErrorMessage(error.response.data.message)
    }
  }

  return (
    <GenericContainer>
      {" "}
      <DemoStepper stepNumber={2}></DemoStepper>
      <Typography sx={{ textAlign: "center" }} variant="h2">
        Add Contact Number
      </Typography>
      {!showSecondStage && !userDetails.is_mobile_number_verified && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <PhoneInput
              placeholder="Enter your mobile number"
              value={newNumber}
              onChange={setNewNumber}
              defaultCountry="GB"
              // inputStyle={{ background: "yellow" }}
              // containerStyle={{ border: "10px solid black" }}
            />
            <Button
              sx={{ p: 1.5 }}
              variant="contained"
              // onClick={submitFirstStage}
              onClick={submitFirstStage}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6">Send verification text</Typography>
              </Box>
            </Button>
          </Box>
        </>
      )}
      {showSecondStage && !userDetails.is_mobile_number_verified && (
        <>
          <Grow in={showSecondStage}>
            <Box>
              <Typography
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  mb: "2rem",
                }}
                onClick={() => setShowSecondStage(false)}
              >
                Back to edit your number
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: "2rem",
                  justifyContent: "space-evenly",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Your 6 digit verification code"
                  variant="outlined"
                  value={SMSToken}
                  onChange={(e) => setSMSToken(e.target.value)}
                  required
                  type="text"
                  sx={{ width: "50%" }}
                />
                <Button
                  sx={{ p: 1.5 }}
                  variant="contained"
                  onClick={submitSecondStage}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6">Submit code</Typography>
                  </Box>
                </Button>
              </Box>

              {infoMessage && <Alert severity="info">{infoMessage}</Alert>}
            </Box>
          </Grow>
        </>
      )}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : ""}
      {userDetails.is_mobile_number_verified ? (
        <Alert severity="success">
          You have successfully added a contact number
        </Alert>
      ) : (
        ""
      )}
      {userDetails.is_mobile_number_verified && (
        <Typography variant="p">
          The contact number we have for you is: {userDetails.mobile_number}
        </Typography>
      )}
      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to="/current-address-verification"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
            <Typography variant="h6">Previous Step</Typography>
          </Box>
        </Button>
        <Button
          component={Link}
          to="/verification-complete"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          disabled={!userDetails.is_mobile_number_verified}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Next Step</Typography>
            <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default AddContactNumber
