import GenericContainer from "../ui/GenericContainer"
import { Typography } from "@mui/material"
const CheckEmail = () => {
  return (
    <GenericContainer>
      {" "}
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Please check your email
      </Typography>
      <Typography variant="b1">
        We have sent you an email with a special link to activate your Factored
        account.
      </Typography>
    </GenericContainer>
  )
}
export default CheckEmail
