import { configureStore } from "@reduxjs/toolkit"
import advanceKycReducer from "./redux/generalSlice"
import popUpSlice from "./redux/popUpSlice"
import headerSlice from "./redux/headerSlice"
export const store = configureStore({
  reducer: {
    advanceKycFlow: advanceKycReducer,
    popUps: popUpSlice,
    header: headerSlice,
  },
})
