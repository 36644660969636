// This page displays the details of the selected advance
// If the advance is already active, we display the payment
// schedule - this is generated by the backend

import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Link } from "react-router-dom"

import { Box, Button, Typography } from "@mui/material"

import axios from "axios"
import GenericContainer from "../ui/GenericContainer"
import ScheduledPaymentsTable from "../components/ScheduledPaymentsTable"
import { formatFiatNumberTwoDecimals } from "../utils/common"

axios.defaults.baseURL = process.env.REACT_APP_ENV_LINK

const IndividualAdvance = () => {
  const styles = {
    summaryContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
    },
    boxWithFlex: {
      display: "flex",
      gap: "0.5rem",
    },
  }

  // We fetch the advance ID from the URL using the below hook
  const { advanceID } = useParams()

  const navigate = useNavigate()

  const [selectedAdvance, setSelectedAdvance] = useState({})
  const [payments, setPayments] = useState([])

  // Initial useEffect to check for the local auth token
  // and fetching of a single advance
  useEffect(() => {
    document.title = `Factored - Advance ${advanceID}`
    const token = window.localStorage.getItem("factored_token")
    if (!token) {
      navigate("/")
    } else if (token) {
      axios.defaults.headers.common["Authorization"] = `Token ${token}`
      getAdvance()
      getPayments()
    }
  }, [])

  // This fetches the advance using the ID from the useParams hook
  const getAdvance = async () => {
    try {
      const response = await axios.get(`api/advances/${advanceID}/`, {})
      // console.log(response.data)
      setSelectedAdvance(response.data)
    } catch (error) {
      console.log(error)
    }
  }
  // This fetches the payment schedule of the advance on the initial render
  const getPayments = async () => {
    try {
      const response = await axios.get(
        `api/advances/advance-payments/${advanceID}`,
      )
      // console.log(response.data)
      setPayments(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const createNewAdvance = async () => {
    try {
      const response = await axios.post("api/advances/create/")
      // console.log(newAdvanceId)
      // console.log("API response", response.data)
      // setNewAdvanceId(response.data.id)
      navigate(`/new-advance-1/${response.data.id}/`)
    } catch (error) {
      console.log("context error", error)
    }
  }

  // This breaks down some of the variable names belonging to each advance
  const {
    id,
    description,
    reason,
    first_line_address,
    second_line_address,
    postcode,
    town_or_city,
    country,
    monthly_rent,
    name_on_bank_account,
    bank_account_number,
    sort_code_bank_account,
    loan_amount,
    estimated_loan_monthly_payment,
    loan_term,
    loan_interest_rate,
    loan_amount_with_arrangement_fee,
    advance_monthly_factored_fee,
  } = selectedAdvance

  // This is a list of table headings for the payments schedule
  const paymentsTableHeadings = ["ID", "Status", "Amount", "Due Date"]

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Advance {id}
      </Typography>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Reason for Advance:</Typography>
        <Typography> {reason}</Typography>
      </Box>
      {/* <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Property address:</Typography>
        <Typography>
          {" "}
          {first_line_address},
          {second_line_address && ` ${second_line_address},`}
          {` ${postcode}`}, {` ${town_or_city}`}, {` ${country}`}
        </Typography>
      </Box> */}
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>
          Monthly rent for this property:
        </Typography>
        <Typography>
          £
          {parseFloat(monthly_rent).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>
          Amount of annual rent you sold:
        </Typography>
        <Typography>
          £
          {parseFloat(loan_amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Advance monthly payments:</Typography>
        <Typography>
          £
          {parseFloat(estimated_loan_monthly_payment).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          )}
        </Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}> Total repayable:</Typography>
        <Typography sx={styles.rightSideEntry}>
          £
          {formatFiatNumberTwoDecimals(
            estimated_loan_monthly_payment * loan_term,
          )}
        </Typography>
      </Box>
      {/* <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>APR:</Typography>
        <Typography sx={styles.rightSideEntry}>
          {(loan_interest_rate * 100).toFixed(2)}%
        </Typography>
      </Box> */}
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Total fees</Typography>
        <Typography sx={styles.rightSideEntry}>
          £
          {formatFiatNumberTwoDecimals(
            estimated_loan_monthly_payment * loan_term - loan_amount,
          )}
        </Typography>
      </Box>

      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Monthly Factored fee</Typography>
        <Typography sx={styles.rightSideEntry}>
          {advance_monthly_factored_fee}%
        </Typography>
      </Box>

      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Advance duration:</Typography>
        <Typography>{loan_term} months</Typography>
      </Box>
      {/* <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Name on bank account:</Typography>
        <Typography>{name_on_bank_account}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Your bank account number:</Typography>
        <Typography>{bank_account_number}</Typography>
      </Box>
      <Box sx={styles.boxWithFlex}>
        <Typography fontWeight={600}>Your bank account sort code:</Typography>
        <Typography>{sort_code_bank_account}</Typography>
      </Box> */}
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Schedule of payments
      </Typography>

      <div>
        <ScheduledPaymentsTable payments={payments}></ScheduledPaymentsTable>
      </div>
      <Box sx={{ width: "100%", display: "flex", gap: "2rem" }}>
        <Button
          component={Link}
          to="/dashboard"
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Back To Dashboard</Typography>
          </Box>
        </Button>
        <Button
          sx={{ p: 1.5, width: "50%" }}
          variant="contained"
          onClick={createNewAdvance}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Create Advance</Typography>
          </Box>
        </Button>
      </Box>
    </GenericContainer>
  )
}

export default IndividualAdvance
