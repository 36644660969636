import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Typography, Button, Box, Tooltip, Alert, Tab } from "@mui/material"
import { useNavigate } from "react-router-dom"

import Snackbar from "@mui/material/Snackbar"
import Slide from "@mui/material/Slide"

import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

const ScheduledPaymentsTable = ({ payments }) => {
  const styles = {
    largeTable: (theme) => ({
      minWidth: "600px",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    }),
    smallTable: (theme) => ({
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    }),
    smallerButton: {
      width: "0.5rem",
      height: "2rem",
    },
  }

  const tableHeadings = ["ID", "Status", "Amount", "Due Date"]

  return (
    <TableContainer component={Paper}>
      <Table sx={styles.largeTable} aria-label="simple table">
        <TableHead sx={{ background: "#4fd1a6" }}>
          <TableRow>
            {tableHeadings.map((heading) => {
              return (
                <TableCell key={heading} align="center">
                  <Typography variant="h6">{heading}</Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => {
            const { id, status, amount, due_date } = payment
            return (
              <TableRow key={id}>
                <TableCell align="center" component="th" scope="row">
                  {id}
                </TableCell>

                <TableCell align="center">{status}</TableCell>

                <TableCell align="center">
                  £{parseFloat(amount).toLocaleString()}
                </TableCell>

                <TableCell align="center">{due_date}</TableCell>

                {/* <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {/* Mobile view below */}
      <Table sx={styles.smallTable}>
        <TableBody>
          {payments.map((payment) => {
            const { id, status, amount, due_date } = payment
            return (
              <TableRow
                key={id}
                component="th"
                scope="row"
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TableCell>
                  <Table>
                    <TableRow>
                      <TableCell sx={{ background: "#4fd1a6" }}>
                        <Typography fontWeight={600}>ID</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{id}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ background: "#4fd1a6" }}>
                        <Typography fontWeight={600}>Status:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{status}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ background: "#4fd1a6" }}>
                        <Typography fontWeight={600}>Amount:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          £{parseFloat(amount).toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ background: "#4fd1a6" }}>
                        <Typography fontWeight={600}>Due date:</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{due_date}</Typography>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default ScheduledPaymentsTable
