import { createTheme } from "@mui/material/styles"
import createBreakpoints from "@mui/system/createTheme/createBreakpoints"

const breakpoints = createBreakpoints({})

const theme = createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
        fullWidth: true,
        InputLabelProps: { sx: { size: "3rem" } },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& label": {
            fontSize: 20,
          },
        },
        input: {
          fontSize: 20,
        },
        listbox: {
          fontSize: 20,
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#4fd1a6",
    },
  },
  typography: {
    fontFamily: "Varela Round, sans-serif",
    button: {
      textTransform: "none",
    },
    h2: {
      fontWeight: 700,
      fontSize: "28px",
    },
    h3: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "29px",
      textTransform: "capitalize",
      color: "#000000",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    // body1: {
    //   fontFamily: "Montserrat, sans-serif",
    //   fontWeight: 500,
    //   fontSize: "18px",
    //   lineHeight: "17px",
    //   color: "#686868",
    //   [breakpoints.up("md")]: {
    //     fontSize: "20px",
    //   },
    // },
  },
})

export default theme
