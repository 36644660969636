import { useState } from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Slider from "@mui/material/Slider"
import MuiInput from "@mui/material/Input"
const Input = styled(MuiInput)`
  width: 22px;
`

export default function InputSlider({
  setNewAdvance,
  loan_amount,
  monthly_rent,
  advanceMaxAmount,
}) {
  const [value, setValue] = useState(loan_amount)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
    setNewAdvance((oldValue) => {
      return { ...oldValue, loan_amount: newValue }
    })
  }

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value.replace(/\,/g, ""))
    setValue(newValue)
    setNewAdvance((oldValue) => {
      return { ...oldValue, loan_amount: newValue }
    })
    // setValue(event.target.value === "" ? "" : Number(event.target.value))
  }

  const marks = [
    {
      value: 3000,
      label: "Min. £3,000",
    },
    {
      value: advanceMaxAmount,
      label: `Max. £${advanceMaxAmount.toLocaleString()}`,
    },
  ]

  return (
    <Box sx={{ width: "100%", gap: "10px" }}>
      <Box sx={{ display: "flex" }}>
        <Typography>How much rent would you like to advance?</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "3px",
            mb: "1rem",
            ml: "auto",
          }}
        >
          <Typography>£</Typography>
          <Input
            value={`${parseFloat(value).toLocaleString()}`}
            size="small"
            onChange={handleInputChange}
            // onBlur={handleBlur}
            sx={{ width: "4rem", fontWeight: "bold" }}
            inputProps={{
              step: 500,
              min: 3000,
              max: advanceMaxAmount,
              type: "text",
            }}
          />
        </Box>
      </Box>

      <Box sx={{ width: "90%", margin: "0 auto" }}>
        <Slider
          value={loan_amount}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          marks={marks}
          step={500}
          min={3000}
          max={advanceMaxAmount}
        />
      </Box>
    </Box>
  )
}
