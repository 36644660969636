// This page allows the user to request a password reset email

import { useState, useEffect } from "react"
import axios from "axios"
import { Button, Typography, TextField, Alert } from "@mui/material"

import GenericContainer from "../ui/GenericContainer"

const ForgotPassword = () => {


  useEffect(() => {
    document.title = "Factored - Forgot Password"
  }, [])

  const [pwResetFormError, setPwResetFormError] = useState("")
  const [formMessage, setFormMessage] = useState("")
  // The below is the default state for the form at the start
  const [pwResetFormData, setPwResetFormData] = useState({
    emailAddress: "",
  })

  const handlePwResetFormSubmit = (e) => {
    e.preventDefault()
    setPwResetFormError("")
    // The below validates the form entries before making request
    // to the backend
    if (!pwResetFormData.emailAddress) {
      setPwResetFormError("Email address is required")
    } else if (
      !pwResetFormData.emailAddress.includes("@") ||
      !pwResetFormData.emailAddress.includes(".")
    ) {
      setPwResetFormError("Email address is must be valid")
    } else {
      const passwordResetEmail = async () => {
        try {
          const response = await axios.post("api/user/forgotpassword/", {
            email: pwResetFormData.emailAddress,
          })
          // console.log("API response", response.data)
          setFormMessage("We sent a password reset link to this email address")
        } catch (error) {
          setPwResetFormError(error.response.data.detail)
        }
      }
      passwordResetEmail()
    }
  }

  return (
    <GenericContainer>
      <Typography variant="h2" sx={{ textAlign: "center" }}>
        Forgot your password?
      </Typography>
      <TextField
        id="outlined-basic"
        label="Email Address"
        variant="outlined"
        value={pwResetFormData.emailAddress}
        onChange={(e) => setPwResetFormData({ emailAddress: e.target.value })}
        required
        type="email"
        name="emailAddress"
        helperText="We will send you an email with a special password
          reset link"
        FormHelperTextProps={{
          style: {
            color: "black",
            fontWeight: "500",
            marginLeft: "2px",
          },
        }}
      />
      {pwResetFormError ? (
        <Alert severity="error">{pwResetFormError}</Alert>
      ) : (
        ""
      )}
      {formMessage && <Alert severity="success">{formMessage}</Alert>}
      <Button
        sx={{ p: 1.5 }}
        variant="contained"
        onClick={handlePwResetFormSubmit}
      >
        <Typography variant="h6">Reset Password</Typography>
      </Button>
    </GenericContainer>
  )
}

export default ForgotPassword
