import * as React from "react"
import { Dropzone, FileMosaic } from "@dropzone-ui/react"

function DragDrop({
  type,
  newRentProtectionFiles,
  setNewRentProtectionFiles,
  newRentAgreementFiles,
  setNewRentAgreementFiles,
}) {
  const updateFiles = (incommingFiles) => {
    if (type === "rentAgreement") {
      console.log(incommingFiles)
      setNewRentAgreementFiles(incommingFiles)
      console.log(newRentAgreementFiles)
    } else if (type === "rentProtection") {
      setNewRentProtectionFiles(incommingFiles)
    }
  }

  const newFiles =
    type === "rentAgreement" ? newRentAgreementFiles : newRentProtectionFiles

  const onDelete = (id) => {
    if (type === "rentAgreement") {
      setNewRentAgreementFiles(newFiles.filter((x) => x.id !== id))
    } else if (type === "rentProtection") {
      setNewRentProtectionFiles(newFiles.filter((x) => x.id !== id))
    }
  }

  return (
    <Dropzone
      onChange={updateFiles}
      value={newFiles}
      accept=".pdf, .png, .jpg, .jpeg, .zip"
      multiple={true}
      maxFileSize={26214400}
      maxFiles={10}
    >
      {newFiles.map((file) => (
        <FileMosaic {...file} key={file.id} onDelete={onDelete} />
      ))}
    </Dropzone>
  )
}

export default DragDrop
